/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import SimpleModal from 'components/Modal/Simple';
import HeaderText from 'components/HeaderText';
import BodyText from 'components/BodyText';
import { addModal } from 'config/modals/registry';
import ernieWithTelescopeTwo from 'public/images/ernie/withTelescopeTwo.svg?url';
import Messages from './index.messages';

const PortalizedModal = ({ onClose }) => (
  <SimpleModal header={Messages.ModalHeader} submit={false} close={{ onClick: onClose }} >
    <div style={{ textAlign: 'center' }}>
      <img src={ernieWithTelescopeTwo} style={{ paddingTop: 20 }} />
      <HeaderText style={{ padding: '8px 0' }}>
        <Messages.Header.Message />
      </HeaderText>
      <BodyText style={{ paddingBottom: 20 }}>
        <Messages.Instructions.Message />
      </BodyText>
    </div>
  </SimpleModal>
);

export default addModal('PortalizedModal', PortalizedModal, { wide: true });
