/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useMemo, useRef, useState } from 'react';
import t from 'tcomb-validation';

import contactEnterpriseSalesEndpoint from 'api/registration/contactEnterpriseSales';

import AutoRow from 'components/AutoRow';
import Border from 'components/Border';
import Button from 'components/Button';
import ExpandableTextArea from 'components/Form/Inputs/ExpandableTextArea';
import FormV2, { FieldInput, FieldOption } from 'components/Form/V2';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';

import CommonMessages from 'config/messages/common';
import { preventStopAll } from 'config/utils/click';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';
import useFormatMessage from 'hooks/intl/useFormatMessage';

import corporatePaymentsIcon from 'public/images/registration/corporatePaymentsIcon.svg?url';

import { CaptchaToken } from 'schemas/common/captcha';
import { Phone } from 'schemas/common/phone';
import Email from 'schemas/common/email/Email';

import Messages from './index.messages';

const ContactSchema = t.struct({
  companyName: t.String,
  firstName: t.String,
  lastName: t.String,
  phoneNumber: Phone,
  email: Email,
  title: t.String,
  website: t.String,
  apSpend: t.enums({
    '10To50Mil': '$10 - $50 million',
    '50To250Mil': '$50 - $250 million',
    '250MilPlus': 'More than $250 million'
  }),
  paperChecksSent: t.enums({
    '500To1000': '500 - 1,000',
    '1001To10000': '1,001 - 10,000',
    'MoreThan10000': 'More than 10,0000'
  }),
  comments: t.maybe(t.String),
  captchaToken: CaptchaToken
});

const ContactEnterpriseSalesHooks = createExecuteApiResultHooks({
  endpoint: contactEnterpriseSalesEndpoint
});

const CorporatePaymentsContact = () => {
  // Hooks
  const formatMessage = useFormatMessage();
  const formRef = useRef();
  const [ formState, setFormState ] = useState({});

  const contactEnterpriseSales = ContactEnterpriseSalesHooks.useExecuteRequest();

  const isSubmitting = ContactEnterpriseSalesHooks.useIsLoading();

  const onSubmit = useMemo(
    () => preventStopAll(
      () => {
        const value = formRef.current.getValue();
        if (value) {
          const { email, phoneNumber, ...formValue } = value;
          contactEnterpriseSales(
            {
              emailAddress: email,
              workPhone: phoneNumber,
              ...formValue,
              accountingPackage: formValue.accountingPackage === 'Other'
                ? formValue.otherAccountingPackage
                : formValue.accountingPackage
            },
            {
              successMessage: <Messages.ConfirmMessage.Message />
            }
          );
        }
      }
    ),
    []
  );

  // Render
  return (
    <>
      <div className="text-center" style={{ lineHeight: '36px', marginBottom: 40 }}>
        <img src={corporatePaymentsIcon} />
        <HeaderText variant={HeaderTextVariants.Larger}>
          <Messages.Header.Message />
        </HeaderText>
        <div className="dark-subtext">
          <Messages.Subheader.Message />
        </div>
      </div>
      <Border
        style={{ backgroundColor: '#f7f7f7', padding: 40 }}
      >
        <FormV2
          ref={formRef}
          className="contact-enterprise-sales-form"
          modelType={ContactSchema}
          value={formState}
          onChange={setFormState}
        >
          <AutoRow gutter={true}>
            <FieldOption
              name="companyName"
              label={<Messages.CompanyName.Message />}
            />
            <FieldOption
              cols={6}
              name="firstName"
              label={<Messages.FirstName.Message />} />
            <FieldOption
              cols={6}
              name="lastName"
              label={<Messages.LastName.Message />}
            />
            <FieldOption
              cols={6}
              name="phoneNumber"
              label={<Messages.WorkPhone.Message />}
            />
            <FieldOption
              cols={6}
              name="email"
              label={<Messages.WorkEmail.Message />}
            />
            <FieldOption
              cols={6}
              name="title"
              label={<Messages.Title.Message />}
            />
            <FieldOption
              cols={6}
              name="website"
              label={<Messages.Website.Message/>}
            >
              <FieldInput
                placeholder={formatMessage(Messages.WebsitePlaceholder)}
              />
            </FieldOption>
            <FieldOption
              cols={6}
              name="apSpend"
              label={<Messages.ApSpend.Message />}
            >
              <FieldInput searchable={false} />
            </FieldOption>
            <FieldOption
              cols={6}
              name="paperChecksSent"
              label={<Messages.PaperChecksSent.Message />}
            >
              <FieldInput searchable={false} />
            </FieldOption>
            <FieldOption
              name="comments"
              label={<Messages.Comments.VpMessage />}
            >
              <ExpandableTextArea
                minRows={5}
                style={{ maxWidth: '100%' }}
              />
            </FieldOption>
            <FieldOption name="captchaToken">
              <FieldInput center={true} />
            </FieldOption>
            <Button
              expand={true}
              isDisabled={isSubmitting}
              isProcessing={isSubmitting}
              onClick={onSubmit}
              type="submit"
            >
              <CommonMessages.Send.Message />
            </Button>
          </AutoRow>
        </FormV2>
      </Border>
    </>
  );
};

export default CorporatePaymentsContact;