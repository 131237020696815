/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useRef, useState, useEffect } from 'react';
import t from 'tcomb-validation';

import selfEnrollInVcc from 'api/onboarding/selfEnrollInVcc';

import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import Icon, { IconTypes } from 'components/Icon';
import BodyText from 'components/BodyText';
import BulletedList, { AutoBullet } from 'components/BulletedList';
import TextBox from 'components/Form/Inputs/TextBox';
import FormV2, { FieldInput, FieldOption, IfFieldDefined } from 'components/Form/V2';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';
import ImageTextLargeButton from 'components/ImageTextLargeButton';
import { FlexColumn, FlexGrid } from 'components/Viewstrap/Grid';

import { PaymentSupport } from 'config/messageLinks';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';
import useFormatMessage from 'hooks/intl/useFormatMessage';
import useQueryParams from 'hooks/location/useQueryParams';
import { GetSecureTokenDataHooks } from 'hooks/registration';

import greenCheckmark from 'public/images/icons/green-checkmark.svg?url';

import createMatchingEmail from 'schemas/common/email/createMatchingEmail';
import Email from 'schemas/common/email/Email';
import { Phone } from 'schemas/common/phone';
import { createMaxLengthStringType } from 'schemas/common/string';

import TermsScrollWrap from 'containers/TermsScrollWrap';
import useWindowWidth from 'hooks/browser/useWindowWidth';
import Alert from 'components/Alert';

import crossMailCircleImage from './crossMailCircle.svg?url';
import Messages, {
  AboutMessages,
  AssistedFaqMessages,
  ConfirmationMessages,
  FaqMessages,
  FormMessages
} from './index.messages';
import moneyMailCircleImage from './moneyMailCircle.svg?url';

const SelfEnrollHooks = createExecuteApiResultHooks({
  endpoint: selfEnrollInVcc
});

const VccSelfEnrollment = () => {
  // Hooks
  const formatMessage = useFormatMessage();
  const windowWidth = useWindowWidth();

  const { emailAddress } = useQueryParams([ 'emailAddress' ]);

  const {
    data: {
      companyName,
      customerCompany,
      isEnrolledInVcc
    },
    token: secureToken
  } = GetSecureTokenDataHooks.useContext() ?? {};

  const [isScrolled, setScrolled] = useState();
  const [hasScrollError, setScrollError] = useState(false);
  useEffect(() => {
    if (!hasScrollError) return;

    if (isScrolled) {
      setScrollError(false);
    }
  }, [isScrolled]);

  const [hasReadStepOne, setReadStepOne] = useState(false);
  const formRef = useRef();
  const [ formState, setFormState ] = useState({
    companyName,
    email: emailAddress
  });

  let isMethodSelected = !!formState?.method;
  let isDirectMethod = formState?.method === 'direct';

  const setMethod = (isDirect) => {
    setFormState({
      ...formState,
      method: isDirect ? 'direct' : 'assisted'
    });
  };

  const formSchema = {
    companyName: t.Any,
    firstName: createMaxLengthStringType(50),
    lastName: createMaxLengthStringType(50),
    title: createMaxLengthStringType(50),
    phone: Phone,
    email: Email,
    verifyEmail: createMatchingEmail(formState.email),
    method: t.enums({
      'direct': 'Email Address',
      'assisted': 'Other Method or Limitations'
    })
  };

  if (!isDirectMethod) {
    formSchema.paymentInstructions = createMaxLengthStringType(1000);
  }

  const executeSelfEnroll = SelfEnrollHooks.useExecuteRequest();
  const isEnrolling = SelfEnrollHooks.useIsLoading();

  const onSubmit = () => {
    if (!isScrolled) {
      setScrollError(true);
      return;
    }

    setScrollError(false);
    const value = formRef.current.getValue();
    if (!value) return;

    const {
      firstName,
      lastName,
      phone,
      email,
      paymentInstructions,
      title,
      method
    } = value;

    executeSelfEnroll({
      contactTitle: title,
      secureToken,
      firstName,
      lastName,
      phone,
      email,
      isAssistedPay: method === 'assisted',
      paymentInstructions
    });
  };

  if (isEnrolledInVcc) {
    return (
      <div className="text-center pad-20 border">
        <img src={greenCheckmark} />
        <HeaderText bold={false} className="pad-8-y" variant={HeaderTextVariants.Medium}>
          <ConfirmationMessages.Header.Message />
        </HeaderText>
        <BodyText>
          {formState.email ? (
            <ConfirmationMessages.InstructionsBeingSent.Message
              email={formState.email}
              followUp={(
                <ConfirmationMessages.InstructionsFollowUp.Message
                  supportLink={PaymentSupport}
                />
              )}
            />
          ) : (
            <ConfirmationMessages.InstructionsSent.Message
              followUp={(
                <ConfirmationMessages.InstructionsFollowUp.Message
                  supportLink={PaymentSupport}
                />
              )}
            />
          )}
        </BodyText>
      </div>
    );
  }

  let isMobile = windowWidth < 650;

  // Step 1: Explain Direct vs Assisted
  if (!hasReadStepOne) {
    return (
      <div style={{padding: isMobile ? '5px' : null}}>
        <HeaderText className="text-center">
          <Messages.SelectFlowHeader.Message />
        </HeaderText>
        <BodyText className="text-center pad-20-y">
          <Messages.SelectFlowSubheader.Message />
        </BodyText>
        <div className="pad-20-y" style={{ maxWidth: 430, margin: '0 auto' }}>
          <FlexGrid gutter={true}>
            <FlexColumn size={6}>
              <ImageTextLargeButton
                imgSrc={moneyMailCircleImage}
                onClick={() => setMethod(true)}
                selected={formState?.method === 'direct'}
                skinny={true}
                style={{ height: 'auto' }}
              >
                YES, email is fine.
              </ImageTextLargeButton>
            </FlexColumn>
            <FlexColumn size={6}>
              <ImageTextLargeButton
                imgSrc={crossMailCircleImage}
                onClick={() => setMethod(false)}
                selected={formState?.method === 'assisted'}
                skinny={true}
                style={{ height: 'auto' }}
              >
                NO, assistance is required.
              </ImageTextLargeButton>
            </FlexColumn>
          </FlexGrid>
          <Button
            expand={true}
            isDisabled={!isMethodSelected}
            onClick={() => setReadStepOne(true)}
          >
            Continue
          </Button>
        </div>
        <BodyText className="text-center pad-20-y">
          <Messages.SelectFlowFooter.Message />
        </BodyText>
      </div>
    );
  }

  // Step 2: Enroll
  return (
    <div style={{ padding: isMobile ? '5px' : null }}>
      <HeaderText
        className="text-center"
        variant={HeaderTextVariants.Larger}
      >
        <Messages.Header.Message />
      </HeaderText>
      <BodyText className="text-center pad-20-y">
        <Messages.Subheader.Message
          companyName={customerCompany}
        />
      </BodyText>
      <FormV2
        display="flex"
        modelType={t.struct(formSchema)}
        onChange={setFormState}
        ref={formRef}
        value={formState}
      >
        <FlexGrid
          className="pad-20-y"
          gutter={true}
        >
          <FieldOption
            cols={isMobile ? 12 : 6}
            disabled={true}
            label={<FormMessages.CompanyName.Message />}
            name="companyName"
          >
            <TextBox
              disabled={true}
            />
          </FieldOption>
          <FieldOption
            cols={isMobile ? 6 : 3}
            label={<FormMessages.FirstName.Message />}
            name="firstName"
          />
          <FieldOption
            cols={isMobile ? 6 : 3}
            label={<FormMessages.LastName.Message />}
            name="lastName"
          />
          <FieldOption
            cols={6}
            label={<FormMessages.Title.Message />}
            name="title"
          />
          <FieldOption
            cols={6}
            label={<FormMessages.Phone.Message />}
            name="phone"
          />
          <FieldOption
            cols={12}
            label="Receive Credit Card Payments Via"
            name="method"
          />
          <FieldOption
            cols={isMobile ? 12 : 6}
            label={isDirectMethod
              ? <FormMessages.PaymentInstructionsEmail.Message />
              : <FormMessages.Email.Message />}
            name="email"
          />
          <FieldOption
            cols={isMobile ? 12 : 6}
            label={<FormMessages.VerifyEmail.Message />}
            name="verifyEmail"
          />
          <IfFieldDefined name="paymentInstructions">
            <FieldOption
              cols={12}
              label={<>
                <FormMessages.PaymentInstructions.Message />
                &nbsp;
                <Tooltip overlay={<div>
                  Our payment specialists will review these instructions and contact you with questions
                  if needed. Please include the following information.
                  <br/><br/>
                  <strong>What special steps are required to process credit card payments for your company?</strong>
                  <br/><br/>
                  <strong>Are there any payment limits, transaction fees, or other special limitations
                    for these payments?</strong>
                </div>}>
                  <Icon type={IconTypes.Info} />
                </Tooltip>
              </>}
              name="paymentInstructions"
            >
              <FieldInput
                inputType="textarea"
                placeholder={formatMessage(FormMessages.PaymentInstructionsPlaceHolder)}
                style={{ minHeight: 100, maxWidth: '100%' }}
              />
            </FieldOption>
          </IfFieldDefined>
          <FlexColumn size={12}>
            Please review our Payment Terms and accept them below.
            <TermsScrollWrap onScrollToEnd={() => setScrolled(true)} />
            {hasScrollError
              ? (
                <Alert variant="failure">
                  You must scroll to the end of the terms to continue.
                </Alert>
              )
              : null}
          </FlexColumn>
          <FlexColumn size={12}>
            <Button
              isDisabled={isEnrolling}
              isProcessing={isEnrolling}
              onClick={onSubmit}
            >
              I have reviewed and accept the Payment Terms
            </Button>
          </FlexColumn>
        </FlexGrid>
      </FormV2>
      {isDirectMethod ? (
        <>
          <HeaderText
            className="pad-40-top"
          >
            <AboutMessages.Header.Message />
          </HeaderText>
          <BodyText className="pad-16-top">
            <AboutMessages.Subheader.Message
              companyName={customerCompany}
            />
          </BodyText>
          <BulletedList className="spaced pad-12-top" variant="bullets">
            <AutoBullet>
              <AboutMessages.Bullet1.Message />
              <AboutMessages.Bullet2.Message />
              <AboutMessages.Bullet3.Message />
              <AboutMessages.Bullet4.Message />
              <AboutMessages.Bullet5.Message />
              <AboutMessages.Bullet6.Message
                companyName={customerCompany}
              />
            </AutoBullet>
          </BulletedList>
          <HeaderText
            className="pad-40-top"
          >
            <FaqMessages.Header.Message/>
          </HeaderText>
          <HeaderText
            className="pad-24-top"
            variant={HeaderTextVariants.Small}
            bold={true}
          >
            <FaqMessages.Question1.Message />
          </HeaderText>
          <BodyText className="pad-12-top">
            <FaqMessages.Question1Answer.Message />
          </BodyText>
          <HeaderText
            className="pad-24-top"
            variant={HeaderTextVariants.Small}
            bold={true}
          >
            <FaqMessages.Question2.Message />
          </HeaderText>
          <BodyText className="pad-12-top">
            <FaqMessages.Question2Answer.Message
              companyName={customerCompany}
            />
          </BodyText>
          <HeaderText
            className="pad-24-top"
            variant={HeaderTextVariants.Small}
            bold={true}
          >
            <FaqMessages.Question3.Message />
          </HeaderText>
          <BodyText className="pad-12-top">
            <FaqMessages.Question3Answer.Message />
          </BodyText>
          <HeaderText
            className="pad-24-top"
            variant={HeaderTextVariants.Small}
            bold={true}
          >
            <FaqMessages.Question4.Message />
          </HeaderText>
          <BodyText className="pad-12-top">
            <FaqMessages.Question4Answer.Message />
          </BodyText>
          <HeaderText
            className="pad-24-top"
            variant={HeaderTextVariants.Small}
            bold={true}
          >
            <FaqMessages.Question5.Message />
          </HeaderText>
          <BodyText className="pad-12-top">
            <FaqMessages.Question5Answer.Message
              companyName={customerCompany}
            />
          </BodyText>
          <HeaderText
            className="pad-24-top"
            variant={HeaderTextVariants.Small}
            bold={true}
          >
            <FaqMessages.Question6.Message />
          </HeaderText>
          <BodyText className="pad-12-top">
            <FaqMessages.Question6Answer.Message
              switchToAssisted={(
                <Button
                  anchor={true}
                  onClick={() => setMethod(false)}
                >
                  <FaqMessages.SwitchLink.Message />
                </Button>
              )}
            />
          </BodyText>
          <BulletedList className="spaced pad-12-top" variant="bullets">
            <AutoBullet>
              <FaqMessages.Question6Bullet1.Message />
              <FaqMessages.Question6Bullet2.Message />
              <FaqMessages.Question6Bullet3.Message />
              <FaqMessages.Question6Bullet4.Message />
              <FaqMessages.Question6Bullet5.Message />
            </AutoBullet>
          </BulletedList>
        </>
      ) : null}
      {!isDirectMethod ? (
        <>
          <HeaderText
            className="pad-40-top"
          >
            <AssistedFaqMessages.Header.Message/>
          </HeaderText>
          <BodyText className="pad-20-top">
            <AssistedFaqMessages.Instructions1.Message
              companyName={customerCompany}
            />
          </BodyText>
          <BodyText className="pad-20-top">
            <AssistedFaqMessages.Instructions2.Message
              switchToDirect={(
                <Button
                  anchor={true}
                  onClick={() => setMethod(true)}
                >
                  <AssistedFaqMessages.SwitchLink.Message />
                </Button>
              )}
            />
          </BodyText>
        </>
      ) : null}
    </div>
  );
};

export default VccSelfEnrollment;