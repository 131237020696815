/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import t from 'tcomb-validation';

import {
  dispatchApiCall,
  hasCacheFailed,
  isCacheLoading
} from 'actions/references';
import {
  getRegistrationResult,
  isRegistering,
  RegistrationReferenceName
} from 'actions/registration';

import { ExpeditedRegistrationError, registerAccountExpedited } from 'api/registration';
import BodyText from 'components/BodyText';
import Button from 'components/Button';
import FormJSX, { FieldOption } from 'components/Form/FormJSX';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';
import ImageTextLargeButton from 'components/ImageTextLargeButton';
import PathLink from 'components/Link/PathLink';
import { SmallBannerNotification, NotificationStyleTypes } from 'components/Notification';
import { MetadataTargetCompanyStatus, ShowAddPaymentAccountStatus } from 'config/constants/registration';
import {
  PrivacyPolicy,
  TermsOfUse,
  PaymentTerms,
  CustomerSupport
} from 'config/messageLinks';
import CommonMessages from 'config/messages/common';

import useQueryParams from 'hooks/location/useQueryParams';
import useFuncRef from 'hooks/react/useFuncRef';
import usePropRef from 'hooks/react/usePropRef';
import { useRegistrationMetadata } from 'hooks/registration';

import personalIcon from 'public/images/icons/personal-icon.svg?url';
import businessIcon from 'public/images/icons/business-icon.svg?url';
import { Address } from 'schemas/common/address';
import { Phone } from 'schemas/common/phone';
import { CompanyNameStrict } from 'schemas/common/companyName';
import { isEnabled } from 'services/FeatureToggles';
import EnterBankInformationFormInputShim, { BankInformationType } from './EnterBankInformation/FormShim';
import ProvideCredentials from './ProvideCredentials';
import Messages from './index.messages';
import showWelcomeModal from './WelcomeModal';
import './index.scss';

const createFormSchema = ({
  hasPersonOption,
  isPersonalUser,
  includePaymentMethod
}) => {
  const type = {
    firstName: t.String,
    lastName: t.String,
    email: t.String,
    address: Address,
    phone: Phone
  };

  if (!isPersonalUser) {
    type.companyName = CompanyNameStrict;
  }

  if (hasPersonOption) {
    type.isPersonalUser = t.maybe(t.Bool);
  }

  if (includePaymentMethod) {
    type.paymentMethod = BankInformationType;
  }

  return t.struct(type);
};

const SelectPersonOrBusiness = ({
  isVendor,
  onChange,
  value
}) => (
  <div className="isPersonSelector">
    <ImageTextLargeButton
      selected={!value}
      skinny={true}
      style={{ height: 'auto', padding: '14px 20px' }}
      imgSrc={businessIcon}
      onClick={() => onChange(false)}
    >
      {isVendor
        ? <Messages.BusinessVendorOption.Message />
        : <Messages.BusinessCustomerOption.Message />}
    </ImageTextLargeButton>
    <ImageTextLargeButton
      selected={value}
      skinny={true}
      style={{ height: 'auto', padding: '14px 20px'}}
      imgSrc={personalIcon}
      onClick={() => onChange(true)}
    >
      {isVendor
        ? <Messages.PersonVendorOption.Message />
        : <Messages.PersonCustomerOption.Message />}
    </ImageTextLargeButton>
  </div>
);

const useOnSubmit = (
  {
    dispatch,
    formRef,
    manualPaymentAccountFormRef,
    signatureFormRef
  },
  params
) => {
  // Hooks
  const paramsRef = usePropRef(params);
  const { current } = useFuncRef(
    () => () => {
      const {
        invitationId,
        secureToken
      } = paramsRef.current;

      const manualForm = manualPaymentAccountFormRef.current
        ? manualPaymentAccountFormRef.current.getValue()
        : true;
      const signatureForm = signatureFormRef.current
        ? signatureFormRef.current.getValue()
        : true;
      const value = formRef.current.getValue();

      if (value && manualForm && signatureForm) {
        dispatch(dispatchApiCall(
          RegistrationReferenceName,
          registerAccountExpedited,
          {
            invitationId,
            secureToken,
            isIndividual: value.isPersonalUser,
            companyName: value.isPersonalUser
              ? `${value.firstName} ${value.lastName}`
              : value.companyName,
            firstName: value.firstName,
            lastName: value.lastName,
            emailAddress: value.email,
            phoneNumber: value.phone,
            address: {
              address1: value.address?.line1,
              address2: value.address?.line2,
              city: value.address?.city,
              stateProvince: value.address?.stateProvince,
              postalCode: value.address?.postalCode,
              country: value.address?.country
            },
            manualPaymentAccount: value.paymentMethod?.manualPaymentAccount
              ? {
                ...value.paymentMethod?.manualPaymentAccount,
                currency: manualForm.currency
              }
              : null,
            plaidPaymentAccount: (value.paymentMethod || {}).plaidPaymentAccount,
            completePaymentAccountData: (value.paymentMethod || {}).completePaymentAccount ? {
              signatureImage: value.paymentMethod.completePaymentAccount.signature,
              signatureText: value.paymentMethod.completePaymentAccount.authorizedSigner,
              taxpayerIdNumber: value.paymentMethod.completePaymentAccount.taxPayerId
            } : null,
            skipPaymentAccount: !value.paymentMethod || value.paymentMethod.mode === 'skipped'
          }
        ));
      }
    }
  );

  // Action
  return current;
};

const ExpeditedRegistration = ({
  secureToken
}) => {
  // Hooks
  const dispatch = useDispatch();

  const { ignoreWelcomeModal } = useQueryParams([ 'ignoreWelcomeModal' ]);

  const [
    isLoading,
    isCompletingRegistration,
    registrationFailure,
    registrationResult
  ] = useSelector(
    state => [
      isCacheLoading(state, RegistrationReferenceName),
      isRegistering(state),
      hasCacheFailed(state, RegistrationReferenceName),
      getRegistrationResult(state)
    ],
    shallowEqual
  );

  const metadata = useRegistrationMetadata();

  const {
    displayAddPaymentAccount,
    from: fromCompany,
    invitationId,
    isValid: isInvitationValid,
    offerPersonalPortal,
    targetCompanyInfo
  } = metadata || {};

  const {
    connectionType,
    isIndividual,
    status: companyStatus
  } = targetCompanyInfo || {};

  const [ formState, setFormState ] = useState({
    companyName: metadata.companyName,
    firstName: metadata.firstName,
    lastName: metadata.lastName,
    email: metadata.email,
    phone: metadata.phoneNumber,
    address: {
      ...metadata.address
    },
    isPersonalUser: isIndividual
  });

  useEffect(
    () => {
      const { isRegistered: isResultRegistered } = registrationResult || {};

      const isRegistered = isResultRegistered
        || companyStatus === MetadataTargetCompanyStatus.PartialRegistration;

      if (displayAddPaymentAccount !== ShowAddPaymentAccountStatus.Required
        && !isRegistered
        && !ignoreWelcomeModal
        && isInvitationValid) {
        dispatch(showWelcomeModal());
      }
    },
    []
  );

  useEffect(
    () => {
      if (registrationFailure === ExpeditedRegistrationError.AccountInUse
        || registrationFailure === ExpeditedRegistrationError.AccountWrongType) {
        setFormState({
          ...formState,
          paymentMethod: null
        });
      }
    },
    [ registrationFailure ]
  );

  useEffect(
    () => {
      if ((registrationResult || {}).isRegistered) {
        Cookies.remove('InvitationId');
      }
    },
    [ (registrationResult || {}).isRegistered ]
  );

  const manualPaymentAccountFormRef = useRef();
  const signatureFormRef = useRef();
  const formRef = useRef();

  const onSubmit = useOnSubmit(
    {
      dispatch,
      formRef,
      manualPaymentAccountFormRef,
      signatureFormRef
    },
    {
      invitationId,
      secureToken
    }
  );

  const formSchema = useMemo(
    () => createFormSchema({
      hasPersonOption: isEnabled('individualWorkflow')
        && (isIndividual || offerPersonalPortal),
      isPersonalUser: isEnabled('individualWorkflow') && formState.isPersonalUser,
      includePaymentMethod: displayAddPaymentAccount !== ShowAddPaymentAccountStatus.Hidden
    }),
    [ formState.isPersonalUser, isIndividual ]
  );

  // Render
  if (!isInvitationValid && !registrationResult && !isCompletingRegistration) {
    return (
      <SmallBannerNotification>
        <Messages.InvalidInvitation.Message
          companyName={fromCompany?.companyName}
        />
      </SmallBannerNotification>
    );
  }

  if (registrationResult || !isInvitationValid || isCompletingRegistration) {
    return (
      <ProvideCredentials
        registrationResult={registrationResult}
        metadata={metadata}
        wasManualFlow={!!((formState.paymentMethod || {}).manualPaymentAccount)}
      />
    );
  }

  let alert = null;
  let disableForm = false;

  if (registrationFailure === ExpeditedRegistrationError.EmailAddressInUse) {
    disableForm = true;
    alert = (
      <SmallBannerNotification type={NotificationStyleTypes.Info}>
        <Messages.EmailAddressInUse.Message loginLink={(
          <PathLink.Logon>
            <Messages.LoginLink.Message />
          </PathLink.Logon>
        )} />
      </SmallBannerNotification>
    );
  } else if (registrationFailure === ExpeditedRegistrationError.AccountInUse) {
    alert = (
      <SmallBannerNotification type={NotificationStyleTypes.Warning}>
        <Messages.AccountInUse.Message customerSupport={CustomerSupport} />
      </SmallBannerNotification>
    );
  } else if (registrationFailure === ExpeditedRegistrationError.AccountWrongType) {
    alert = (
      <SmallBannerNotification type={NotificationStyleTypes.Warning}>
        <Messages.AccountWrongType.Message />
      </SmallBannerNotification>
    );
  } else if (registrationFailure) {
    alert = (
      <SmallBannerNotification type={NotificationStyleTypes.Error}>
        <Messages.Failure.Message customerSupport={CustomerSupport} />
      </SmallBannerNotification>
    );
  }

  return (
    <div className="expedited-registration">
      {alert}
      <FormJSX
        ref={formRef}
        modelType={formSchema}
        disabled={disableForm}
        value={formState}
        onChange={v => setFormState(v)}
      >
        <FieldOption name="isPersonalUser"
          inputComponent={SelectPersonOrBusiness}
          inputProps={{ isVendor: connectionType === 'Vendor' }}
          overrideLabel={(
            <HeaderText
              className="vp-label-indent"
              style={{ paddingBottom: 8 }}
              variant={HeaderTextVariants.Small}
            >
              <Messages.IsPersonalUser.Message />
            </HeaderText>
          )}
        />
        <FieldOption name="companyName" label={Messages.CompanyName} />
        <FieldOption name="firstName" cols={6} label={Messages.FirstName} />
        <FieldOption name="lastName" cols={6} label={Messages.LastName} />
        <FieldOption name="email" cols={6} label={Messages.Email} disabled={true} />
        <FieldOption
          name="phone"
          cols={6}
          label={formState.isPersonalUser
            ? Messages.PersonalPhone
            : Messages.Phone}
        />
        <FieldOption name="address" />
        <FieldOption name="paymentMethod"
          cols={12}
          overrideLabel={(
            <HeaderText
              className="vp-label-indent"
              style={{ paddingBottom: 8 }}
              variant={HeaderTextVariants.Small}
            >
              <Messages.PaymentMethod.Message />
            </HeaderText>
          )}
          inputComponent={EnterBankInformationFormInputShim}
          inputProps={{
            formRef: manualPaymentAccountFormRef,
            signatureFormRef: signatureFormRef,
            needsSignature: connectionType === 'Customer',
            invitationId,
            disabled: disableForm,
            canSkip: displayAddPaymentAccount === ShowAddPaymentAccountStatus.Shown
          }}
        />
        <Button
          expand={true}
          onClick={onSubmit}
          isDisabled={isLoading}
          isProcessing={isLoading}
        >
          <CommonMessages.Submit.Message />
        </Button>
      </FormJSX>
      <div style={{ textAlign: 'center', paddingTop: 8 }}>
        <BodyText>
          <Messages.Disclaimer.Message
            privacyPolicy={PrivacyPolicy}
            tou={TermsOfUse}
            paymentTerms={PaymentTerms}
          />
        </BodyText>
      </div>
    </div>
  );

};

export default ExpeditedRegistration;
