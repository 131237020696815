/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import canUseDOM from 'utils/browser/canUseDOM';
import { MapStateToProps, MapDispatchToProps } from './index.connect';

const KEEP_ALIVE_MINUTE_INTERVAL = 5;

class KeepAliveService extends Component {
  constructor(props) {
    super(props);

    this.updateKeepAlive = this.updateKeepAlive.bind(this);
  }

  shouldComponentUpdate() {
    return false;
  }

  updateKeepAlive() {
    if (this.props.isAuthorizedToUseApp && moment() >= this.verifyKeepAlive) {
      this.verifyKeepAlive = moment().add(KEEP_ALIVE_MINUTE_INTERVAL, 'minutes');
      this.props.keepAlive();
    }
  }

  componentDidMount() {
    this.verifyKeepAlive = moment().add(KEEP_ALIVE_MINUTE_INTERVAL, 'minutes');
    if (canUseDOM) {
      document.body.addEventListener('focus', this.updateKeepAlive, true);
      document.body.addEventListener('keydown', this.updateKeepAlive, true);
    }
  }

  componentWillUnmount() {
    if (canUseDOM) {
      document.body.removeEventListener('focus', this.updateKeepAlive);
      document.body.removeEventListener('keydown', this.updateKeepAlive);
    }
  }

  render() {
    // Does not provide context, should not be passed children
    return null;
  }
}

export default connect(MapStateToProps, MapDispatchToProps)(KeepAliveService);
