/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { Component, Children } from 'react';
import PropTypes from 'prop-types';
import canUseDOM from 'utils/browser/canUseDOM';
import resolveMenuRenderer from 'components/Menu/MenuRenderer';

export default class RendererProvider extends Component {
  static propTypes = {
    renderers: PropTypes.object
  };

  static contextTypes = {
    renderer: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.updateMenuRenderer = this.updateMenuRenderer.bind(this);
  }

  // you must specify what you’re adding to the context
  static childContextTypes = {
    renderer: PropTypes.shape({
      menuRenderer: PropTypes.object,
      menuItemRenderer: PropTypes.func,
      badgeRenderer: PropTypes.func,
      buttonRenderer: PropTypes.func,
      cardRenderer: PropTypes.func,
      columnRenderer: PropTypes.func,
      modalRenderer: PropTypes.func,
      rowRenderer: PropTypes.func,
      tableRenderer: PropTypes.func,
      tableColumnRenderer: PropTypes.func,
      tableRowRenderer: PropTypes.func,
      tooltipRenderer: PropTypes.func
    })
  };

  updateMenuRenderer() {
    let menuRenderer = resolveMenuRenderer();

    this.setState({
      menuRenderer: menuRenderer
    });
  }

  componentWillMount() {
    this.updateMenuRenderer();
  }

  componentDidMount() {
    if (!canUseDOM) {
      throw new Error('Only client side supported.');
    }

    window.addEventListener('resize', this.updateMenuRenderer);
  }

  componentWillUnmount() {
    if (!canUseDOM) {
      throw new Error('Only client side supported.');
    }

    window.removeEventListener('resize', this.updateMenuRenderer);
  }

  getChildContext() {
    return {
      renderer: {
        ...this.context.renderer,
        menuRenderer: this.state.menuRenderer,
        ...this.props.renderers
      }
    };
  }

  render() {
    let { children } = this.props;
    return Children.only(children);
  }

}
