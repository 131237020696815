/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useState } from 'react';

import patchCompanySettings from 'api/settings/patchCompanySettings';

import BodyText from 'components/BodyText';
import Border from 'components/Border';
import Button from 'components/Button';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';
import { FlexColumn, FlexGrid } from 'components/Viewstrap/Grid';

import CommonMessages from 'config/messages/common';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';
import useCompanySettings from 'hooks/identity/useCompanySettings';
import useIdentity from 'hooks/identity/useIdentity';

import BlueHeaderOnWhiteLayout from 'layouts/BlueHeaderOnWhite';

import businessIcon from 'public/images/registration/businessIcon.svg?url';
import InvoicingIcon from 'public/images/registration/invoicingIcon.png';
import individualIcon from 'public/images/registration/individualIcon.svg?url';
import paymentsIcon from 'public/images/registration/paymentsIcon.svg?url';

import { didUserRegisterBefore, Releases } from 'services/ReleaseService';

import {
  AccountingTypeUsageMessages,
  BusinessRelationshipUsageMessages
} from './index.messages';

const PatchCompanySettingsHooks = createExecuteApiResultHooks({
  endpoint: patchCompanySettings
});

const AccountingTypeUsagePrompt = ({
  onComplete: onCompleteProp
}) => {
  // Hooks
  const [ selected, setSelected ] = useState([]);

  const toggleSelected = (id) => {
    setSelected((prevState) => {
      if (!prevState.includes(id)) {
        return [ ...prevState, id ];
      }

      return prevState.filter(i => i !== id);
    });
  };

  const onChange = (event, id) => {
    const isChecked = event.target.checked;
    setSelected((prevState) => {
      if (isChecked && !prevState.includes(id)) {
        return [ ...prevState, id ];
      }

      if (!isChecked && prevState.includes(id)) {
        return prevState.filter(i => i !== id);
      }

      return prevState;
    });
  };

  const executePatch = PatchCompanySettingsHooks.useExecuteRequest();
  const isSubmitting = PatchCompanySettingsHooks.useIsLoading();

  const onComplete = async (selection) => {
    let accountingType;

    const withAr = selection.includes('AccountsReceivable');
    const withAp = selection.includes('AccountsPayable');

    if (withAr && withAp) {
      accountingType = 'Both';
    } else if (withAr) {
      accountingType = 'AccountsPayable';
    } else if (withAp) {
      accountingType = 'AccountsReceivable';
    }

    const [ success ] = await executePatch({ accountingType });

    if (success) {
      onCompleteProp(selection);
    }
  };

  // Render
  return (
    <>
      <div className="text-center">
        <HeaderText className="pad-16-bottom" variant={HeaderTextVariants.PageHeader}>
          <AccountingTypeUsageMessages.Header.Message />
        </HeaderText>
        <BodyText color="darkGrey" className="pad-40-bottom">
          <AccountingTypeUsageMessages.Subheader.Message />
        </BodyText>
        <HeaderText variant={HeaderTextVariants.Small} className="pad-16-bottom">
          <AccountingTypeUsageMessages.Instructions.VpMessage />
        </HeaderText>
      </div>
      <FlexGrid gutter={true}>
        <FlexColumn size={6}>
          <Border
            onClick={() => toggleSelected('AccountsReceivable')}
            className="text-center pad-16-bottom pad-40-top"
          >
            <img className="pad-40-bottom" src={InvoicingIcon} style={{ maxWidth: 80 }} />
            <HeaderText bold={true} variant={HeaderTextVariants.Small} className="pad-16-bottom">
              <AccountingTypeUsageMessages.AccountsReceivableHeader.Message />
            </HeaderText>
            <input
              type="checkbox"
              checked={selected.includes('AccountsReceivable')}
              className="vp-toggle"
              onChange={e => onChange(e, 'AccountsReceivable')}
            />
          </Border>
        </FlexColumn>
        <FlexColumn size={6}>
          <Border
            onClick={() => toggleSelected('AccountsPayable')}
            className="text-center pad-16-bottom pad-40-top"
          >
            <img className="pad-40-bottom" src={paymentsIcon} style={{ maxWidth: 80 }} />
            <HeaderText bold={true} variant={HeaderTextVariants.Small} className="pad-16-bottom">
              <AccountingTypeUsageMessages.AccountsPayableHeader.Message />
            </HeaderText>
            <input
              type="checkbox"
              checked={selected.includes('AccountsPayable')}
              className="vp-toggle"
              onChange={e => onChange(e, 'AccountsPayable')}
            />
          </Border>
        </FlexColumn>
      </FlexGrid>
      <Button
        expand={true}
        isDisabled={!selected.length || isSubmitting}
        isProcessing={isSubmitting}
        onClick={() => onComplete(selected)}
      >
        {selected.length === 1
          ? <AccountingTypeUsageMessages.OneSelectionContinue.Message />
          : <CommonMessages.Continue.Message/>}
      </Button>
    </>
  );
};

const BusinessRelationshipTypeUsagePrompt = ({
  onComplete: onCompleteProp
}) => {
  // Hooks
  const [ selected, setSelected ] = useState([]);

  const toggleSelected = (id) => {
    setSelected((prevState) => {
      if (!prevState.includes(id)) {
        return [ ...prevState, id ];
      }

      return prevState.filter(i => i !== id);
    });
  };

  const onChange = (event, id) => {
    const isChecked = event.target.checked;
    setSelected((prevState) => {
      if (isChecked && !prevState.includes(id)) {
        return [ ...prevState, id ];
      }

      if (!isChecked && prevState.includes(id)) {
        return prevState.filter(i => i !== id);
      }

      return prevState;
    });
  };

  const executePatch = PatchCompanySettingsHooks.useExecuteRequest();
  const isSubmitting = PatchCompanySettingsHooks.useIsLoading();

  const onComplete = async (selection) => {
    let businessRelationshipType;

    const withBusinesses = selection.includes('BusinessOnly');
    const withConsumers = selection.includes('ConsumerOnly');

    if (withBusinesses && withConsumers) {
      businessRelationshipType = 'All';
    } else if (withBusinesses) {
      businessRelationshipType = 'BusinessOnly';
    } else if (withConsumers) {
      businessRelationshipType = 'ConsumerOnly';
    }

    const [ success ] = await executePatch({ businessRelationshipType });

    if (success) {
      onCompleteProp(selection);
    }
  };

  // Render
  return (
    <>
      <div className="text-center">
        <HeaderText className="pad-16-bottom" variant={HeaderTextVariants.PageHeader}>
          <BusinessRelationshipUsageMessages.Header.Message />
        </HeaderText>
        <BodyText color="darkGrey" className="pad-40-bottom">
          <BusinessRelationshipUsageMessages.Subheader.Message />
        </BodyText>
        <HeaderText variant={HeaderTextVariants.Small} className="pad-16-bottom">
          <BusinessRelationshipUsageMessages.Instructions.VpMessage />
        </HeaderText>
      </div>
      <FlexGrid gutter={true}>
        <FlexColumn size={6}>
          <Border
            onClick={() => toggleSelected('ConsumerOnly')}
            className="text-center pad-16-bottom pad-40-top"
          >
            <img className="pad-40-bottom" src={individualIcon} style={{ maxWidth: 80 }} />
            <HeaderText bold={true} variant={HeaderTextVariants.Small} className="pad-8-bottom">
              <BusinessRelationshipUsageMessages.ConsumerOnlyHeader.Message />
            </HeaderText>
            <BodyText className="pad-16-bottom">
              <BusinessRelationshipUsageMessages.ConsumerOnlyDescription.Message />
            </BodyText>
            <input
              type="checkbox"
              checked={selected.includes('ConsumerOnly')}
              className="vp-toggle"
              onChange={e => onChange(e, 'ConsumerOnly')}
            />
          </Border>
        </FlexColumn>
        <FlexColumn size={6}>
          <Border
            onClick={() => toggleSelected('BusinessOnly')}
            className="text-center pad-16-bottom pad-40-top"
          >
            <img className="pad-40-bottom" src={businessIcon} style={{ maxWidth: 80 }} />
            <HeaderText bold={true} variant={HeaderTextVariants.Small} className="pad-8-bottom">
              <BusinessRelationshipUsageMessages.BusinessOnlyHeader.Message />
            </HeaderText>
            <BodyText className="pad-16-bottom">
              <BusinessRelationshipUsageMessages.BusinessOnlyDescription.Message />
            </BodyText>
            <input
              type="checkbox"
              checked={selected.includes('BusinessOnly')}
              className="vp-toggle"
              onChange={e => onChange(e, 'BusinessOnly')}
            />
          </Border>
        </FlexColumn>
      </FlexGrid>
      <Button
        expand={true}
        isDisabled={!selected.length || isSubmitting}
        isProcessing={isSubmitting}
        onClick={() => onComplete(selected)}
      >
        {selected.length === 1
          ? <BusinessRelationshipUsageMessages.OneSelectionContinue.Message />
          : <CommonMessages.Continue.Message/>}
      </Button>
    </>
  );
};


const UsagePromptWorkflowStep = ({
  active,
  updateState
}) => {
  // Hooks
  const [ step, setStep ] = useState('accountingType');

  const identity = useIdentity();
  const {
    accountingType,
    registrationSource
  } = useCompanySettings();

  // Persist it because we are going to change it
  const [ originalAccountingType ] = useState(accountingType);

  const willShow = (originalAccountingType == null || originalAccountingType === 'Unknown')
    && registrationSource === 'OpenRegistration'
    && !didUserRegisterBefore(Releases.R285, identity)
    && identity.permissions.modifyCompanySettings;

  useEffect(
    () => updateState(willShow ? 'loaded' : 'skipped'),
    []
  );

  // Render
  if (!active || !willShow) return <></>;

  let content;
  if (step === 'accountingType') {
    const onComplete = (accountingTypes) => {
      if (accountingTypes.includes('AccountsReceivable')) {
        setStep('businessRelationshipType');
        return;
      }

      updateState('completed');
    };

    content = (
      <AccountingTypeUsagePrompt
        onComplete={onComplete}
      />
    );
  } else if (step === 'businessRelationshipType') {
    content = (
      <BusinessRelationshipTypeUsagePrompt
        onComplete={() => updateState('completed')}
      />
    );
  }

  return (
    <BlueHeaderOnWhiteLayout
      contentWidth={650}
      hideLoginLink={true}
    >
      <div className="viewstrap">
        {content}
      </div>
    </BlueHeaderOnWhiteLayout>
  );
};

export default UsagePromptWorkflowStep;
