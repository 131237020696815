/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { dateMapper } from 'api/common/mappers/coreMappers';
import { PaymentRole } from 'schemas/payments/payments';
import { PaymentTrackerStepType } from 'schemas/payments/tracker';
import { getPaymentMethodEnum } from 'utils/paymentUtils';

export function mapPaymentTracker(apiTracker, apiPaymentMethod, isIclOptimized = false, masterInvoiceId) {
  if (!apiTracker || apiTracker.length === 0) {
    return null;
  }
  let steps = apiTracker.map((apiStep) => {
    let stepType = PaymentTrackerStepType.meta.map.Unknown;
    if (Object.keys(PaymentTrackerStepType.meta.map).includes(apiStep.step)) {
      stepType = PaymentTrackerStepType.meta.map[apiStep.step];
    }
    return {
      date: dateMapper(apiStep.date),
      deliverByDate: dateMapper(apiStep.deliveryDate),
      stepType,
      isVoid: apiStep.isVoid,
      isRefund: apiStep.isRefund,
      firstName: apiStep.firstName,
      lastName: apiStep.lastName,
      reason: apiStep.reason,
      amount: apiStep.amount,
      referenceNumber: apiStep.referenceNumber,
      paymentMethodType: apiStep.paymentMethodType === 'Unknown'
        ? null
        : getPaymentMethodEnum(apiStep.paymentMethodType),
      toAccountSuffix: apiStep.toAccountSuffix,
      toCompanyName: apiStep.toCompanyName,

      splitAmount: apiStep.splitAmount,
      splitReferenceNumber: apiStep.splitReferenceNumber,
      splitPaymentMethodType: getPaymentMethodEnum(apiStep.splitPaymentMethodType)
    };
  });
  return {
    steps,
    paymentMethodType: getPaymentMethodEnum(apiPaymentMethod, isIclOptimized),
    masterInvoiceId
  };
}

export function mapSettlementRole(apiSettlementRole) {
  switch (apiSettlementRole) {
    case 1:
      return PaymentRole.meta.map.VccPayment;
    case 2:
      return PaymentRole.meta.map.VccPaymentReroute;
    case 3:
      return PaymentRole.meta.map.VccPaymentPartialReroute;
    case 4:
      return PaymentRole.meta.map.VccPaymentPrefund;
    case 5:
      return PaymentRole.meta.map.VccPaymentOverLimitSplit;
    case 6:
      return PaymentRole.meta.map.VccPaymentRefund;
    case 7:
      return PaymentRole.meta.map.VccPaymentPartialRefund;
    case 8:
      return PaymentRole.meta.map.VccPaymentReimbursement;
    default:
      return PaymentRole.meta.map.None;
  }
}
