/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';

import ProvideIsMobile from 'decorators/ProvideIsMobile';

import CheckTileBackground from 'images/payments/checkPreviewTile.png';
import MicrLine from 'components/MicrLine';

import CheckRouting from 'images/payments/checkRouting.svg';

function LabelledMicr({ value, label, size = 20 }) {
  return (
    <div style={{display: 'inline-block', verticalAlign: 'top'}}>
      <MicrLine value={value} size={size} />
      {label
        ? (
          <div style={{
            position: 'absolute',
            borderTop: '2px solid #1e88e5',
            width: '100%',
            textAlign: 'center',
            marginTop: '-7px'
          }}>
            <div style={{
              position: 'absolute',
              border: '1px solid #1e88e5',
              borderRadius: '4px',
              fontWeight: 'bold',
              padding: '5px',

              // center horiz and overflow container
              width: '120px',
              marginLeft: '-60px',
              left: '50%',

              top: '10px',
              backgroundColor: '#ecf7fd'
            }}>
              {label}
            </div>
          </div>
        )
        : null}
    </div>
  );
}

const MicrSection = ProvideIsMobile(({ country, isMobile}) => {
  if (country === 'CA') {
    return (
      <>
        {isMobile
          ? null
          : <LabelledMicr value="  T001TU " />}
        <LabelledMicr value=" T" />
        <LabelledMicr value="12345-678" label={<>
          Routing Number<br />
          e.g. 12345-678
        </>} />
        <LabelledMicr value="T  " />
        <LabelledMicr value="4567-89012" label={<>
          Account Number<br />
          4567-89012
        </>} />
        <LabelledMicr value="U" />
      </>
    );
  }

  // US
  return (
    <>
      <LabelledMicr value="  T" />
      <LabelledMicr value="123456789" label={<>Routing Number<br />9 digits</>} />
      <LabelledMicr value="T  " />
      <LabelledMicr value="1234567890" label={<>Account Number<br />Up to 16 digits</>} />
      <LabelledMicr value="U" />
    </>
  );
});

function PaperCheckRoutingExample({ country = 'US' }) {
  return (
    <div style={{
      backgroundImage: `url(${CheckTileBackground})`,
      backgroundColor: '#eee',
      display: 'inline-block',
      border: '1px solid #ddd',
      borderRadius: '4px',
      width: '100%',
      maxWidth: '400px',
      marginBottom: '70px'
    }}>
      <div style={{
        opacity: 0.9,
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        position: 'absolute'
      }}>
        &nbsp;
      </div>

      {/* Content */}
      <div>
        <div>
          <CheckRouting width="100%" />
        </div>

        {/* MICR */}
        <div style={{textAlign: 'left'}}>
          <MicrSection country={country} />
        </div>
      </div>
    </div>
  );
}

export default PaperCheckRoutingExample;