/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import t from 'tcomb-form';
import Border from 'components/Border';
import FormJSX from 'components/Form/FormJSX';
import FieldOption from 'components/Form/FieldOption';
import { AddSignatureMethod } from 'schemas/payments/bankAccount';
import SignatureCanvas from 'components/Signature';
import { Phone } from 'schemas/common/phone';
import Alert from 'components/Alert';
import Button from 'components/Button';
import PhoneNumber from 'components/PhoneNumber';
import phoneWithMessage from 'public/images/icons/phone-with-message.svg?url';
import Messages from './index.messages';
import { MapStateToProps, MapDispatchToProps } from './index.connect';
import './index.scss';

const PhoneForm = t.struct({
  phone: Phone
});

export class AddSignature extends Component {
  static propTypes = {
    /**
      * This should be any unique ID (recommend using a GUID that might be associated with whatever the signature is used for).
      * It will be used as a key in the Application Document Store, so that upon completion of the workflow, the signature image can be deleted.
      */
    signatureId: PropTypes.string.isRequired,
    /** If you provide a base64 string representing an image signature, it will be shown upon first render and the user can choose to edit it. */
    mobileSignature: PropTypes.string,
    /** Phone number to be populated in the proper field */
    phoneNumber: PropTypes.string,
    showRequiredLabel: PropTypes.bool,
    /** Callback that will receive as its only argument the base64 representation of the signature image on every change */
    onChange: PropTypes.func
  };

  static defaultProps = {
    showRequiredLabel: false
  }

  constructor(props) {
    super(props);

    this.state = {
      formState: {
        id: props.signatureId,
        signatureMethod: 'draw'
      },
      phoneFormState: {
        phone: props.phoneNumber
      },
      showMobileSignature: false,
      emptySignatureError: false
    };
  }

  componentDidMount() {
    let signatureId = this.props.signatureId.split('-').join('');
    // force one call in case they had previously uploaded an imageData
    this.props.loadMobileSignature(signatureId, !this.props.isAuthorizedToUseApp);
  }

  componentWillUnmount() {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
    if (this.pollTimeout) {
      clearTimeout(this.pollTimeout);
    }
  }

  componentWillUpdate(newProps, newState) {
    if (newState.formState.signatureMethod === 'mobile' && newState.showMobileSignature
      && this.props.mobileSignature !== newProps.mobileSignature
    || newState.formState.signatureMethod === 'mobile' && newProps.mobileSignature
      && !this.state.showMobileSignature && newState.showMobileSignature) {
      if (this.props.onChange) {
        let image = newProps.mobileSignature.split('base64,').pop();
        this.props.onChange(image, this.state.phoneFormState.phone);
      }
    }
  }

  onPhoneFormChange = (phoneFormState) => {
    this.setState({
      phoneFormState
    });
  }

  onFormChange = (formState) => {
    let image = null;
    if (formState.signatureMethod === 'mobile') {
      if (this.state.showMobileSignature && this.props.mobileSignature) {
        this.setState({
          emptySignatureError: false
        });
        image = this.props.mobileSignature.split('base64,').pop();
      }
    }
    if (this.props.onChange) {
      this.props.onChange(image);
    }
    this.setState({
      formState,
      emptySignatureError: false
    });
  }

  reset = () => {
    this.setState({
      emptySignatureError: false,
      showMobileSignature: false
    });
    if (this.signature) {
      this.signature.clear();
      if (this.props.onChange) {
        this.props.onChange(null);
      }
    }
  }

  onSend = (evt) => {
    evt.preventDefault();
    this.sendMobileMessage();
  }

  sendMobileMessage(phone = null, sendConfirmation = false) {
    let formState;
    if (this.phoneFormRef) {
      formState = this.phoneFormRef.getValue();
    }
    if (!this.state.showMobileSignature && formState || phone) {
      this.props.signWithMobile(
        this.props.signatureId,
        phone || formState.phone,
        sendConfirmation,
        this.props.invitationId
      );
      // begin polling every 3 sec with 5 min timeout
      if (this.pollTimeout) {
        clearTimeout(this.pollTimeout);
      }
      this.pollTimeout = setTimeout(() => {
        if (this.pollInterval) {
          clearInterval(this.pollInterval);
        }
      }, 5 * 60 * 1000);
      let signatureId = this.props.signatureId.split('-').join('');
      if (this.pollInterval) {
        clearInterval(this.pollInterval);
      }
      this.pollInterval = setInterval(() => {
        if (this.state.formState.signatureMethod === 'mobile' && this.state.showMobileSignature) {
          this.props.loadMobileSignature(signatureId, !this.props.isAuthorizedToUseApp);
        }
      }, 4000);

      this.setState({
        showMobileSignature: true
      });
    }
  }

  cleanupStorage = () => {
    if (this.state.formState.signatureMethod === 'mobile') {
      // cleanup the signature storage
      this.props.deleteSignatureStorage(
        this.props.signatureId.split('-').join(''),
        !this.props.isAuthorizedToUseApp
      );
    }
  }

  onStrokeEnd = () => {
    if (this.props.onChange) {
      let image = this.signature.getImageData();
      if (!image) {
        this.setState({
          emptySignatureError: true
        });
      } else {
        this.setState({
          emptySignatureError: false
        });
      }
      this.props.onChange(image);
    }
  }

  render() {
    let phoneNumber = <strong><PhoneNumber value={this.state.phoneFormState.phone}/></strong>;
    let imagePanel = (
      <div>
        <div className="signature-panel"
          style={{borderBottomRightRadius: 0, borderBottomLeftRadius: 0, borderBottom: 'none'}}>
          <div className="caret"></div>
          <div className="vp-label"><FormattedMessage {...Messages.FollowInstructions} values={{phoneNumber}}/></div>
          <div className="dividers">
            {[
              <Button key="changePhoneLink" anchor={true} className="mobile-signature-links"
                onClick={() => this.setState({showMobileSignature: false})}>
                <FormattedMessage {...Messages.buttons.ChangePhone} />
              </Button>,
              <Button key="resendLink" anchor={true} className="mobile-signature-links"
                onClick={() => this.sendMobileMessage(this.state.phoneFormState.phone, true)}>
                <FormattedMessage {...Messages.buttons.Resend} />
              </Button>
            ]}
          </div>
        </div>
        <div className="signature-panel text-center" style={{borderTopRightRadius: 0, borderTopLeftRadius: 0}}>
          {this.props.mobileSignature
            ? <img src={this.props.mobileSignature} width={350}/>
            : <span className="vp-label"><FormattedMessage {...Messages.SignatureWillAppear} /></span>}
        </div>
      </div>
    );
    let enterMobile = (
      <div className="signature-panel">
        <div className="caret"></div>
        <FormJSX
          modelType={PhoneForm}
          value={this.state.phoneFormState}
          ref={ref => this.phoneFormRef = ref}
          onChange={this.onPhoneFormChange}
        >
          <span className="vp-label" cols={7}><FormattedMessage {...Messages.formLabels.EnterMobile} /></span>
          <FieldOption name="phone" cols={3} willHideLabel={true} placeholder={Messages.placeholders.PhoneNumber}/>
          <Button cols={2} onClick={this.onSend} className="skinny-button" type="submit">
            <FormattedMessage {...Messages.buttons.Send} />
          </Button>
        </FormJSX>
      </div>
    );
    let mobilePanel = this.state.showMobileSignature ? imagePanel : enterMobile;
    let requiredLabel = this.props.showRequiredLabel ? <span className="required-asterisk">&nbsp;*</span> : null;

    if (this.props.variant === 'new') {
      return (
        <div>
          <Border noPadding={true} className="add-signature-container" style={{height: 260}}>
            {this.state.formState.signatureMethod === 'draw'
              ? <div style={{padding: 5}}>
                {this.state.emptySignatureError
                  ? <Alert variant="failure">
                    <FormattedMessage {...Messages.EnterSignatureError} />
                  </Alert>
                  : null}
                <div className="clear-button">
                  <Button anchor={true} onClick={() => {
                    this.signature.clear();
                    if (this.props.onChange) {
                      this.props.onChange(null);
                    }
                    this.setState({
                      emptySignatureError: false
                    });
                  }}>
                    <FormattedMessage {...Messages.buttons.Clear} />
                  </Button>
                </div>
                <SignatureCanvas
                  ref={r => this.signature = r}
                  required={true}
                  width={600}
                  hideBorder={true}
                  hideClear={true}
                  onEnd={this.onStrokeEnd}
                />
              </div>
              : (
                <div className="new-enter-signature-section">
                  <div className="enter-mobile-section pad-12">
                    <div className="row">
                      <div className="col-6">
                        <span className="type-strong">
                          <FormattedMessage {...Messages.SignByMobileHeader} />
                        </span>
                        <br/>
                        <FormattedMessage {...Messages.SignByMobileInstructions} />
                      </div>
                      <div className="col-6">
                        {this.state.showMobileSignature ? (
                          <div className="dividers text-right">
                            <br/>
                            <Button key="changePhoneLink" anchor={true}
                              onClick={() => this.setState({showMobileSignature: false})}>
                              <FormattedMessage {...Messages.buttons.ChangePhone} />
                            </Button>
                            <Button key="resendLink" anchor={true}
                              onClick={() => this.sendMobileMessage(this.state.phoneFormState.phone, true)}>
                              <FormattedMessage {...Messages.buttons.Resend} />
                            </Button>
                          </div>
                        ) : (
                          <FormJSX
                            modelType={PhoneForm}
                            value={this.state.phoneFormState}
                            ref={ref => this.phoneFormRef = ref}
                            onChange={this.onPhoneFormChange}
                            customConfig={{addPadding: 'none'}}
                          >
                            <FieldOption name="phone" cols={7}
                              willHideLabel={true}
                              placeholder={Messages.placeholders.PhoneNumber}/>
                            <Button analyticsId="SendSignByMobile" cols={5}
                              onClick={this.onSend} className="skinny-button" type="submit">
                              <FormattedMessage {...Messages.buttons.Send} />
                            </Button>
                          </FormJSX>
                        )}
                      </div>
                    </div>
                  </div>
                  {this.props.mobileSignature ? (
                    <div className="new-view-signature-section text-center">
                      <img className="new-view-signature-section"
                        style={{height: '100%'}}
                        src={this.props.mobileSignature} />
                    </div>
                  ) : (
                    <div className="new-view-signature-section text-center">
                      <div className="instructions-section subtext">
                        <div style={{marginBottom: 8}}><img src={phoneWithMessage} /></div>
                        <div><FormattedMessage {...Messages.SignatureInstructions} /></div>
                      </div>
                    </div>
                  )}
                </div>
              )}
          </Border>
          <div className="text-tiny pad-y-8">
            {this.state.formState.signatureMethod === 'draw' ? (
              <div>
                <FormattedMessage {...Messages.SwitchToMobileMessage} />
                <Button
                  analyticsId="SwitchToMobile"
                  anchor={true}
                  onClick={() => this.setState({formState: {...this.state.formState, signatureMethod: 'mobile'}})}
                >
                  <FormattedMessage {...Messages.SwitchToMobileAction} />
                </Button>
              </div>
            ) : (
              <div>
                <FormattedMessage {...Messages.SwitchToDrawMessage} />
                <Button
                  analyticsId="SwitchToDraw"
                  anchor={true}
                  onClick={() => this.setState({formState: {...this.state.formState, signatureMethod: 'draw'}})}
                >
                  <FormattedMessage {...Messages.SwitchToDrawAction} />
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="add-signature-container">
        <FormJSX
          modelType={AddSignatureMethod}
          value={this.state.formState}
          ref={ref => this.formRef = ref}
          onChange={this.onFormChange}
        >
          <FieldOption name="id" willHideLabel={true} type="hidden" />
          <FieldOption name="signatureMethod" label={Messages.formLabels.Signature}
            showRequiredLabel={this.props.showRequiredLabel} inputComponent={t.form.Radio}
          />
          <div className="vp-label">
            <FormattedMessage {...Messages.formLabels.Signature} />
            {requiredLabel}
          </div>
          {this.state.formState.signatureMethod === 'draw'
            ? <div className="signature-panel" style={{padding: 5}}>
              <div className="caret draw"></div>
              {this.state.emptySignatureError
                ? <Alert variant="failure">
                  <FormattedMessage {...Messages.EnterSignatureError} />
                </Alert>
                : null}
              <div className="clear-button">
                <Button anchor={true} onClick={() => {
                  this.signature.clear();
                  if (this.props.onChange) {
                    this.props.onChange(null);
                  }
                  this.setState({
                    emptySignatureError: false
                  });
                }}>
                  <FormattedMessage {...Messages.buttons.Clear} />
                </Button>
              </div>
              <SignatureCanvas
                ref={r => this.signature = r}
                required={true}
                width={600}
                hideBorder={true}
                hideClear={true}
                onEnd={this.onStrokeEnd}
              />
            </div>
            : mobilePanel}
        </FormJSX>
      </div>
    );
  }
}

export default connect(MapStateToProps, MapDispatchToProps, null, { forwardRef: true })(
  AddSignature
);
