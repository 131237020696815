/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import _ from 'lodash';
import pick from 'lodash/pick';
import qs from 'qs';
import Cookies from 'js-cookie';
import { InvoiceType } from 'schemas/invoicing/invoice';
import { convertInvoiceTypeToUrlTypeCode } from 'config/routes/inApp/invoices.utils';
import moment from 'moment';

function mapParamsToQuery(baseUrl, params = {}) {
  return `${baseUrl}?${qs.stringify(params)}`;
}

function includeFilters(baseUrl, params, ...filterNames) {
  let queryParams = pick(params, filterNames);
  queryParams = _.mapKeys(queryParams, (value, key) => {
    return `filter_${key}`;
  });

  if (Object.keys(queryParams).length < 1) {
    return `${baseUrl}`;
  }

  return `${baseUrl}?${qs.stringify(queryParams)}`;
}

function includeOnly(baseUrl, allowedParams, allowedFilters, params) {
  if (!params) return baseUrl;

  let queryParams = {};

  allowedParams.forEach((param) => {
    if (params[param]) {
      queryParams[param] = params[param];
    }
  });

  allowedFilters.forEach((param) => {
    if (params[param]) {
      queryParams[`filter_${param}`] = params[param];
    }
  });

  if (!Object.keys(queryParams).length) return baseUrl;

  return `${baseUrl}?${qs.stringify(queryParams)}`;
}

function createEditInvoiceLink(params) {
  let typeCode = convertInvoiceTypeToUrlTypeCode(params.invoiceType || params.type);
  return `/invoices/edit/${typeCode}/${params.id}`;
}

function invoiceDetailsLink(params) {
  if (!params) throw new Error('Details link requires params');
  if (!params.invoiceId) throw new Error('Invoice ID is required for details link');
  if (!params.invoiceType) throw new Error('Invoice Type required for details link');

  if (params.invoiceType === InvoiceType.meta.map.APInboxItem) {
    return `/bills/vpinbox/${params.invoiceId}/create`;
  }
  let typeCode = convertInvoiceTypeToUrlTypeCode(params.invoiceType);

  if (!typeCode) throw new Error(`Cannot determine the typeCode from: ${params.invoiceType}`);

  let nav = 'invoices';
  if ((params.invoiceType === InvoiceType.meta.map.Payable && params.isMine === true)
   || (params.invoiceType === InvoiceType.meta.map.Receivable && params.isMine === false)
   || typeCode === 'o') {
    nav = 'bills';
  }
  return `/${nav}/details/${typeCode}/${params.invoiceId}`;
}

export const LinksModel = {
  logon: {
    index: {
      _id: 'LogOn_Index',
      _path: (params) => {
        return includeOnly('/logon', ['reason', 'redirectPath'], [], params);
      }
    },
    newResetPassword: {
      _id: 'LogOn_NewResetPassword',
      _path: '/logon/resetPassword'
    },
    resetPassword: {
      _id: 'LogOn_ResetPassword',
      _nonSpa: true,
      _path: '/resetPassword'
    },
    logout: {
      _id: 'LogOn_LogOut',
      _nonSpa: true,
      _path: '/logon/logoff'
    }
  },
  dashboard: {
    index: {
      _id: 'Dashboard_Index',
      _path: '/dashboard'
    }
  },
  invoices: {
    index: {
      _id: 'Invoices_Index',
      _path: '/invoices'
    },
    inbox: {
      _id: 'Invoices_Inbox', // Deprecated
      _path: '/invoices/list'
    },
    list: {
      _id: 'Invoices_List',
      _path: params => includeOnly('/invoices/list', ['sort'], ['addressBookId', 'status'], params)
    },
    companySummary: {
      _id: 'Invoices_CompanySummary',
      _path: '/invoices/summary'
    },
    recurring: {
      _id: 'Invoices_Recurring',
      _path: '/invoices/recurring'
    },
    qbonline: {
      _id: 'Invoices_QbOnline',
      _path: '/invoices/qbonline'
    },
    vpx: {
      _id: 'Invoices_Vpx',
      _path: '/invoices/vpx'
    },
    create: {
      _id: 'Invoices_Create',
      _path: (params) => {
        let brickmanUrl = '/invoices/create';

        if (params.copyId) {
          brickmanUrl = `${brickmanUrl}?copyId=${params.copyId}`;
          if (params.copyType) {
            brickmanUrl = `${brickmanUrl}&copyType=${params.copyType}`;
          }
        }

        if (params.id) {
          brickmanUrl = `${brickmanUrl}?companyId=${params.id}`;
        }
        return brickmanUrl;
      }
    },
    createRecurring: {
      _id: 'Invoices_Create_Recurring',
      _path: (params) => {
        return '/invoices/create?type=recurring';
      }
    },
    editRecurring: {
      _id: 'Invoices_Edit_Recurring',
      _path: createEditInvoiceLink
    },
    previewRecurring: {
      _id: 'Invoices_Preview_Recurring',
      _path: (params) => {
        return `/invoices/details/rp/${params.id}`;
      }
    },
    edit: {
      _id: 'Invoices_Edit',
      _path: createEditInvoiceLink
    },
    details: {
      _id: 'Invoices_Details',
      _path: invoiceDetailsLink
    },
    detailsPdf: {
      _id: 'Invoices_DetailsPdf',
      _nonSpa: true,
      _path: (params) => {
        if (!params) return null;

        let query = {};

        if (params.secureToken) {
          query.invoiceId = params.id;
          query.accessToken = params.secureToken;

          return `/dynamicinvoices/renderinvitationpdf?${qs.stringify(query)}`;
        } if (!params.invoiceType) {
          throw new Error('Invoice Type is not set');
        } else if (params.inline) {
          query.contentDisposition = 'inline';
        }

        query.documentType = params.invoiceType.toLowerCase();

        return `/dynamicinvoices/renderpdf/${params.id}?${qs.stringify(query)}`;
      }
    },
    recordPayment: {
      _id: 'Invoices_RecordPayment',
      _path: (params) => {
        return includeOnly('/invoices/recordPayment', ['sort', 'page', 'addressBookId'], [], params);
      }
    },
    insuranceInfo: {
      _id: 'Invoice_insurance_info',
      _path: 'https://support.viewpost.com/hc/en-us/articles/115009554928-Invoice-Insurance'
    }
  },
  bills: {
    index: {
      _id: 'Bills_Index',
      _path: '/bills'
    },
    mailbox: {
      _id: 'Bills_Mailbox',
      _path: '/bills/mailbox'
    },
    inbox: {
      _id: 'Bills_Inbox',
      _path: (params) => {
        let baseUrl = '/bills/inbox';
        if (!params) return baseUrl;

        return includeFilters(baseUrl,
          params,
          'status',
          'companyId',
          'beginDate',
          'endDate'
        );
      }
    },
    list: {
      _id: 'Bills_List',
      _path: params => includeOnly(
        '/bills/list',
        ['sort', 'setupInbox'],
        ['addressBookId', 'status'],
        params
      )
    },
    details: {
      _id: 'Bills_Details',
      _path: invoiceDetailsLink
    },
    companySummary: {
      _id: 'Bills_CompanySummary',
      _path: '/bills/summary'
    },
    statements: {
      _id: 'Bills_Statements',
      _path: '/bills/statements'
    },
    statementDetails: {
      _id: 'Bills_StatementDetails',
      _path: (params) => {
        if (params.dueDate) {
          // enforce consistent format
          params.dueDate = moment(params.dueDate).format('MM/DD/YYYY');
        }

        return includeFilters(`/bills/statements/details`, params, 'companyId', 'dueDate', 'customerId');
      }
    },
    vpx: {
      _id: 'Bills_VPX',
      _path: '/bills/vpx'
    },
    paymentQueue: {
      _id: 'Bills_PaymentQueue',
      _path: '/bills/paymentQueue'
    },
    paymentQueueReview: {
      _id: 'Bills_PaymentQueueReview',
      _path: '/bills/paymentQueue/review'
    },
    outOfBand: {
      attachments: {
        _id: 'OOB_Attachments',
        _path: params => `/bills/oob/${params.id}/attachments`
      },
      edit: {
        _id: 'OOB_Edit',
        _path: params => `/bills/oob/${params.id}/edit`
      }
    },
    create: {
      _id: 'Bills_Create',
      _path: (params) => {
        let brickmanUrl = '/bills/create';
        if (params.id) {
          brickmanUrl = `${brickmanUrl}?companyId=${params.id}`;
        }

        if (params.copyId) {
          brickmanUrl = `${brickmanUrl}?copyId=${params.copyId}`;
          if (params.copyType) {
            brickmanUrl = `${brickmanUrl}&copyType=${params.copyType}`;
          }
        }

        return brickmanUrl;
      }
    },
    edit: {
      _id: 'Bills_Edit',
      _path: (params) => {
        return `/bills/edit/p/${params.id}`;
      }
    },
    recordPayment: {
      _id: 'Bills_RecordPayment',
      _path: (params) => {
        return includeOnly('/bills/recordPayment', ['sort', 'page', 'addressBookId'], [], params);
      }
    }
  },
  payments: {
    index: {
      _id: 'Payments_Index',
      _path: '/payments'
    },
    sent: {
      _id: 'Payments_Sent',
      _path: (params, state) => {
        let baseUrl = '/payments/sent';

        if (!params) return baseUrl;

        return includeFilters(baseUrl,
          params,
          'status'
        );
      }
    },
    auto: {
      _id: 'Payments_Auto_Pay',
      _path: '/payments/auto'
    },
    detailsSent: {
      _id: 'Payments_Details_Sent',
      _path: (params) => {
        let baseUrl = '/payments/details/sent';

        if (!params || !Object.keys(params).length || !params.settlementId) {
          return baseUrl;
        }

        return `${baseUrl}/${params.settlementId}`;
      }
    },
    received: {
      _id: 'Payments_Received',
      _path: (params, state) => {
        let baseUrl = '/payments/received';

        if (!params) return baseUrl;

        return includeFilters(baseUrl,
          params,
          'status'
        );
      }
    },
    detailsReceived: {
      _id: 'Payments_Details_Received',
      _path: (params) => {
        let baseUrl = '/payments/details/received';

        if (!params || !Object.keys(params).length || !params.id) {
          return baseUrl;
        }

        return `${baseUrl}/${params.id}`;
      }
    },
    receipt: {
      _id: 'Receipt',
      _path: (params) => {
        let baseUrl = '/payments/receipt';

        if (!params || !Object.keys(params).length || !params.id) {
          return baseUrl;
        }

        return `${baseUrl}/${params.id}`;
      }
    },
    viewReceivable: {
      _id: 'View_Receivable',
      _path: params => params.id ? invoiceDetailsLink({
        invoiceId: params.id,
        invoiceType: InvoiceType.meta.map.Receivable,
        isMine: true
      }) : null
    },
    viewPayable: {
      _id: 'View_Payable',
      _path: ({ id }) => id ? invoiceDetailsLink({
        invoiceId: id,
        invoiceType: InvoiceType.meta.map.Payable,
        isMine: true
      }) : null
    },
    downloadReceived: {
      _id: 'Payments_Download_Received',
      _nonSpa: true,
      _path: params => mapParamsToQuery('/ajax/paymentReceipt/download', params)
    },
    downloadRemittance: {
      _id: 'Payments_Download_Remittance',
      _nonSpa: true,
      _path: params => mapParamsToQuery('/ajax/receivedPayment/download', params)
    },
    downloadSent: {
      _id: 'Payments_Download_Sent',
      _nonSpa: true,
      _path: params => mapParamsToQuery('/ajax/settlement/download', params)
    },
    qbonline: {
      _id: 'Quick_Books_Online',
      _path: '/payments/qbonline'
    },
    checkimage: {
      _id: 'Check_Image',
      _path: (params) => {
        const baseUrl = '/api/payments/checkImage?';
        if (!params) {
          return baseUrl;
        }

        return `${baseUrl}${qs.stringify(params)}`;
      }
    },
    checkbook: {
      _id: 'Send_Payment',
      _path: ({ addressBookId } = {}) => addressBookId
        ? `/payments/send?addressBookId=${addressBookId}` : '/payments/send'
    }
  },
  companySettings: {
    index: {
      _id: 'CompanySettings_Index',
      _path: '/settings'
    },
    profile: {
      _id: 'CompanySettings_Profile',
      _path: '/settings/companyProfile'
    },
    editProfile: {
      _id: 'CompanySettings_EditProfile',
      _path: '/settings/companyProfile/edit'
    },
    preferences: {
      _id: 'CompanySettings_Preferences',
      _path: '/settings/companyPreferences'
    },
    subscription: {
      _id: 'CompanySettings_Subscription',
      _path: '/settings/subscription'
    },
    closeAccount: {
      _id: 'CompanySettings_CloseAccount',
      _path: '/settings/closeAccount'
    },
    logos: {
      _id: 'CompanySettings_Logos',
      _path: '/settings/logos'
    },
    groups: {
      _id: 'CompanySettings_Groups',
      _path: '/settings/groups'
    },
    addGroup: {
      _id: 'CompanySettings_AddGroup',
      _path: '/settings/groups/add'
    },
    userPermissionsLearnMore: {
      _id: 'CompanySettings_UserPermissionsLearnMore',
      _path: 'https://support.viewpost.com/hc/en-us/articles/217701707-User-Permissions',
      _nonSpa: true
    },
    editGroup: {
      _id: 'CompanySettings_EditGroup',
      _path: (params, state) => {
        if (!params || !params.id) return null;

        if (params.isAdmin) {
          return `/settings/groups/edit/${params.id}?type=admin`;
        }

        return `/settings/groups/edit/${params.id}`;
      }
    },
    users: {
      _id: 'CompanySettings_Users',
      _path: (params) => {
        if (!params || params.status !== 'all') return '/settings/users';
        return '/settings/users?filter_status=all';
      }
    },
    addUser: {
      _id: 'CompanySettings_AddUser',
      _path: '/settings/users/add'
    },
    editUser: {
      _id: 'CompanySettings_EditUser',
      _path: (params, state) => {
        if (!params || !params.userId) return null;

        return `/settings/users/edit/${params.userId}`;
      }
    },
    paymentMethods: {
      _id: 'CompanySettings_PaymentMethods',
      _path: '/settings/paymentMethods'
    },
    verifyAccount: {
      _id: 'CompanySettings_VerifyAccount',
      _path: params => `/settings/paymentMethods?verifyAccountId=${params.id}`
    },
    switchCompanies: {
      _id: 'CompanySettings_SwitchCompanies',
      _path: '/settings/switchCompanies'
    },
    fees: {
      _id: 'CompanySettings_Fees',
      _path: '/settings/fees'
    },
    feeDetails: {
      _id: 'CompanySettings_FeeDetails',
      _path: '/settings/fees/details'
    },
    reports: {
      _id: 'CompanySettings_Reports',
      _path: '/settings/reports'
    },
    vpx: {
      _id: 'CompanySettings_Vpx',
      _path: '/settings/discount'
    },
    manageVpxVendors: {
      _id: 'CompanySettings_ManageVpxVendors',
      _path: '/settings/discount/manageVendors'
    },
    newVpxStrategy: {
      _id: 'CompanySettings_NewVpxStrategy',
      _path: '/settings/discount/strategy/create'
    },
    editVpxStrategy: {
      _id: 'CompanySettings_EditVpxStrategy',
      _path: (params) => {
        return `/settings/discount/strategy/edit/${params.id}`;
      }
    },
    addVendorsToVpxStrategy: {
      _id: 'CompanySettings_AddVendorsToVpxStrategy',
      _path: (params) => {
        return `/settings/discount/strategy/addVendors/${params.id}`;
      }
    }
  },
  userSettings: {
    index: {
      _id: 'UserSettings_Index',
      _path: '/profile'
    },
    userAccount: {
      _id: 'UserSettings_Account',
      _path: '/settings/userAccount'
    }
  },
  sync: {
    index: {
      _id: 'Sync_Index',
      _path: '/sync'
    },
    syncLanding: {
      _id: 'Sync_Landing',
      _path: () => {
        return `/sync/landing`;
      }
    },
    syncSettings: {
      _id: 'Sync_Settings',
      _path: () => {
        return `/sync/settings`;
      }
    },
    syncPayments: {
      _id: 'Sync_Payments',
      _path: () => {
        return `/sync/payments`;
      }
    },
    syncOnboarding: {
      _id: 'Sync_Onboarding',
      _path: (params) => {
        if (params.syncPackage) {
          return `/sync/onboarding/${params.syncPackage}`;
        }
        return '/sync';
      }
    },
    syncHistory: {
      _id: 'Sync_History',
      _path: () => {
        return `/sync/history`;
      }
    },
    syncPackage: {
      _id: 'Sync_Package',
      _path: (params) => {
        if (!params.name) return null;

        return `/sync/Download/${params.name}`;
      }
    },
    disconnectPageQuickBooksOnline: {
      _id: 'Sync_DisconnectPageQuickBooksOnline',
      _path: () => {
        return '/sync/onboarding/QuickBooksOnline?status=disconnected';
      }
    },
    dotNetDownload: {
      _id: 'Sync_DotNetDownload',
      _path: 'http://www.microsoft.com/en-us/download/details.aspx?id=17851',
      _nonSpa: true
    },
    quickBookDesktopGuide: {
      _id: 'Sync_QBDesktopGuide',
      _path: 'https://support.viewpost.com/hc/en-us/articles/206687918-Getting-Started-Guide',
      _nonSpa: true
    },
    quickBooksOnlineDocumentation: {
      _id: 'Sync_QBODocumentation',
      _path: 'https://support.viewpost.com/hc/en-us/sections/204855588-Documentation-for-QuickBooks-Online-Sync',
      _nonSpa: true
    },
    microsoftDynamicsGettingStarted: {
      _id: 'MicrosoftDynamics_GettingStarted',
      _path: 'https://support.viewpost.com/hc/en-us/articles/205120838-Getting-started-with-Viewpost-Sync-for-Microsoft-Dynamics-GP',
      _nonSpa: true
    },
    microsoftDynamicsGuide: {
      _id: 'MicrosoftDynamics_Guide',
      _path: 'https://support.viewpost.com/hc/en-us/articles/205104328-Syncing-with-Microsoft-Dynamics-GP',
      _nonSpa: true
    },
    syncAgent: {
      _id: 'Sync_Agent',
      _path: (params) => {
        if (!params.name) return null;

        return `/Sync/DownloadAgentInstaller?package=${params.name}`;
      },
      _nonSpa: true
    },
    NetSuiteHelpLink: {
      _id: 'NetSuite_Auth_Guide',
      _path: 'https://support.viewpost.com/hc/en-us/sections/115001158347',
      _nonSpa: true
    },
    SageLiveHelpLink: {
      _id: 'SageLive_Auth_Guide',
      _path: 'https://support.viewpost.com/hc/en-us/sections/360000136308',
      _nonSpa: true
    },
    RiskmasterHelpLink: {
      _id: 'Riskmaster_Auth_Guide',
      _path: 'https://support.viewpost.com/hc/en-us/sections/360000315548',
      _nonSpa: true
    },
    PaymentRequirements: {
      default: {
        _id: 'Payment_Requirements',
        _path: 'https://support.viewpost.com/hc/en-us/articles/115007618967',
        _nonSpa: true
      }
    },
    AutoPayTimeFrame: {
      default: {
        _id: 'AutoPay_Time_Frame',
        _path: 'https://support.viewpost.com/hc/en-us/articles/115008391308',
        _nonSpa: true
      }
    },
    GeneralSync: {
      default: {
        _id: 'General_Sync',
        _path: 'https://support.viewpost.com/hc/en-us/articles/115012938388',
        _nonSpa: true
      }
    },
    ModifyVendorSettings: {
      default: {
        _id: 'Modify_Vendor_Settings',
        _path: 'https://support.viewpost.com/hc/en-us/articles/115009378887',
        _nonSpa: true
      }
    }
  },
  marketingSite: {
    index: {
      _id: 'MarketingSite_Index',
      _nonSpa: true,
      _path: 'https://www.viewpost.com'
    },
    pricing: {
      _id: 'MarketingSite_Pricing',
      _nonSpa: true,
      _path: 'https://www.viewpost.com/pricing'
    },
    aboutUs: {
      _id: 'MarketingSite_AboutUs',
      _nonSpa: true,
      _path: 'https://www.viewpost.com/about-us'
    },
    terms: {
      _id: 'MarketingSite_Terms',
      _nonSpa: false,
      _path: '/terms-and-conditions'
    },
    paymentTerms: {
      _id: 'MarketingSite_PaymentTerms',
      _nonSpa: false,
      _path: '/terms-and-conditions/payment'
    },
    vccTerms: {
      _id: 'MarketingSite_VccTerms',
      _nonSpa: false,
      _path: '/terms-and-conditions/vccTerms'
    },
    c2bPaymentTerms: {
      _id: 'MarketingSite_C2BPaymentTerms',
      _nonSpa: false,
      _path: '/terms-and-conditions/consumer-payment'
    },
    cassTerms: {
      _id: 'MarketingSite_CassTerms',
      _nonSpa: false,
      _path: '/terms-and-conditions/cass-terms'
    },
    privacy: {
      _id: 'MarketingSite_Privacy',
      _nonSpa: false,
      _path: '/terms-and-conditions/privacy'
    },
    eulaPdf: {
      _id: 'MarketingSite_EulaPdf',
      _nonSpa: false,
      _path: '/terms-and-conditions/eula'
    },
    features: {
      _id: 'MarketingSite_Features',
      _nonSpa: true,
      _path: 'https://www.viewpost.com/features'
    },
    security: {
      _id: 'MarketingSite_Security',
      _nonSpa: true,
      _path: 'https://www.viewpost.com/security'
    },
    contact: {
      _id: 'MarketingSite_Contact',
      _nonSpa: true,
      _path: 'https://www.viewpost.com/contact'
    },
    lsqAgreement: {
      _id: 'MarketingSite_LSQVPXYAgreement',
      _nonSpa: true,
      _path: 'https://www.viewpost.com/terms/'
    }
  },
  help: {
    main: {
      _id: 'Help_Main',
      _nonSpa: true,
      _path: 'https://support.viewpost.com/hc/en-us'
    },
    multiStep: {
      _id: 'Help_MultiStep',
      _nonSpa: true,
      _path: 'https://support.viewpost.com/hc/en-us/articles/215796427'
    },
    acceptEarlyPayments: {
      _id: 'Support_Early_Payments_Accept',
      _nonSpa: true,
      _path: `https://support.viewpost.com/hc/en-us/articles/216314537-How-can-I-request-Early-
      Payment-?src=dashboard_early_pay_invoice`
    },
    offerEarlyPayments: {
      _id: 'Support_Early_Payments_Offer',
      _nonSpa: true,
      _path: `https://support.viewpost.com/hc/en-us/articles/204862557-How-do-I-get-started-offering-Early
      -Payment-?src=dashboard_early_pay_bill`
    },
    verifyMicroDeposits: {
      _id: 'Support_Verify_Micro_Deposits',
      _nonSpa: true,
      _path: 'https://support.viewpost.com/hc/en-us/articles/115004546227-Verifying-a-Bank-Account'
    },
    sendingPayments: {
      _id: 'Support_Sending_Payments',
      _nonSpa: true,
      _path: 'https://support.viewpost.com/hc/en-us/articles/204165678-Sending-Payments'
    },
    multipleAccounts: {
      _id: 'Support_Multiple_Accounts',
      _nonSpa: true,
      _path: 'https://support.viewpost.com/hc/en-us/articles/115006751748-Have-Multiple-Accounts-'
    },
    plaidSecurity: {
      _id: 'Plaid_Security',
      _nonSpa: true,
      _path: 'https://plaid.com/security/'
    },
    stripePricing: {
      _id: 'Stripe_Pricing',
      _nonSpa: true,
      _path: 'https://stripe.com/us/pricing'
    }
  },
  tasks: {
    index: {
      _id: 'Tasks_Index',
      _path: '/tasks'
    }
  },
  onboarding: {
    invitation: {
      _id: 'Onboarding_Invitation',
      _path: ({ invitationId } = {}) => `/onboarding/invitation/${invitationId}`
    }
  },
  registration: {
    index: {
      _id: 'Registration_Index',
      _path: (params) => {
        if (params && params.invitationId) {
          return `/register/invitation/${params.invitationId}`;
        }

        if (Cookies.get('ReferralToken', { secure: true})) {
          return `/register/referral/${Cookies.get('ReferralToken', { secure: true })}`;
        } if (Cookies.get('InvitationId', { secure: true})) {
          return `/register/invitation/${Cookies.get('InvitationId', { secure: true })}`;
        }

        return '/register';
      }
    }
  },
  mobile: {
    ios: {
      invoice: {
        appStore: {
          _id: 'Mobile_ios_appstore_invoice',
          _path: 'https://itunes.apple.com/us/app/viewpost-invoice/id1049112141'
        }
      }
    }
  },
  fileImport: {
    index: {
      _id: 'FileImport_Index',
      _path: '/fileImport/list'
    },
    faq: {
      _id: 'FileImport_Requirements',
      _path: 'https://support.viewpost.com/hc/en-us/articles/235446247-File-Import-FAQ'
    },
    addressBookDetailInstructions: {
      _id: 'FileImport_AddressBookDetailInstructions',
      _path: 'https://support.viewpost.com/hc/en-us/articles/235161288-Address-Book-File-Import-Detail-Instructions'
    },
    addressBookRequirements: {
      _id: 'FileImport_AddressBookRequirements',
      _path: 'https://support.viewpost.com/hc/en-us/articles/235165927-Address-Book-Import-Field-Requirements'
    },
    fileImportTemplates: {
      _id: 'FileImport_ImportTemplates',
      _path: 'https://support.viewpost.com/hc/en-us/article_attachments/115018806628/Viewpost_Address_Book_File_Import_Template.csv'
    },
    mappingFiles: {
      _id: 'FileImport_MappingFiles',
      _path: 'https://support.viewpost.com/hc/en-us/articles/235233227-Mapping-CSV-Import-Files'
    },
    generalImportRequirements: {
      _id: 'FileImport_GeneralImportRequirements',
      _path: 'https://support.viewpost.com/hc/en-us/articles/234945308-General-File-Import-Requirements'
    }
  },
  consumer: {
    bills: {
      open: {
        _id: 'Consumer_Bills_Open',
        _path: '/bills/open'
      },
      closed: {
        _id: 'Consumer_Bills_Closed',
        _path: '/bills/closed'
      }
    },
    autopay: {
      _id: 'Consumer_AutoPay',
      _path: '/autopay'
    },
    fees: {
      _id: 'Consumer_Fees',
      _path: '/fees'
    },
    settings: {
      _id: 'Consumer_Settings',
      _path: '/userSettings'
    }
  }
};

function GenerateLinkRef(modelNode) {
  // If we have only a metadata node, return the ID
  if (_.every(Object.keys(modelNode), (key) => {
    return key.charAt(0) === '_';
  })) {
    return modelNode._id;
  }

  let linkRef = {...modelNode};
  // Remove the meta fields
  delete linkRef._id;
  delete linkRef._path;
  delete linkRef._nonSpa;

  // Recurse into the model node to generate the ref node
  Object.keys(linkRef).forEach((key) => {
    linkRef[key] = GenerateLinkRef(linkRef[key]);
  });

  return linkRef;
}

// This takes the LinksModel above and decomposes it to no longer have metadata
// and instead the metadata node will be the link reference ID
// e.g.,, LinkModel.dashboard.list._id === LinkRef.dashboard.list
// This is the preferred way to reference the IDs, exposing the LinksModel is overkill
// and frankly, confusing
export const LinkRef = GenerateLinkRef(LinksModel);

function GenerateLinkMap(modelNode, toExtract) {
  if (_.every(Object.keys(modelNode), (key) => {
    return key.charAt(0) === '_';
  })) {
    return {
      [modelNode._id]: toExtract(modelNode)
    };
  }

  let linkMap = {};
  Object.keys(modelNode).forEach((key) => {
    linkMap = {
      ...linkMap,
      ...GenerateLinkMap(modelNode[key], toExtract)
    };
  });

  return linkMap;
}

// This just reverses the links model so that a look up of Link Ref mapped to
// Link Path can be done
export const LinkMap = GenerateLinkMap(LinksModel, modelNode => modelNode._path);

export const IsSpaLinkMap = GenerateLinkMap(LinksModel,
  modelNode => modelNode._nonSpa !== undefined ? !modelNode._nonSpa : true);

export const GetPath = (ref, params, analyticsToken, state) => {
  let path = LinkMap[ref];

  if (process.env.NODE_ENV !== 'production') {
    if (!path) {
      console.warn(`No path found for ${ref}`);
    }
  }

  let pathString = null;

  if (_.isFunction(path)) {
    pathString = path(params, state);
  } else {
    pathString = path;
  }

  if (analyticsToken && pathString) {
    if (pathString.includes('?')) {
      // TODO: SOMETHING
    } else {
      pathString = `${pathString}?src=${analyticsToken}`;
    }
  }

  return pathString || '/';
};
