/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import Image from 'components/Viewstrap/Image';
import cityOneImage from 'public/images/backgrounds/city1.svg?url';
import cityTwoImage from 'public/images/backgrounds/city2.svg?url';
import cityThreeImage from 'public/images/backgrounds/city3.svg?url';
import ernieWithBinocularsImage from 'public/images/ernie/with-binoculars.svg?url';
import ernieFemaleWavingImage from 'public/images/ernie/female-waving.svg?url';
import ernieHighfiveAnotherImage from 'public/images/ernie/highfive-another.svg?url';
import ernieTangledUpImage from 'public/images/ernie/untangling-wires-red.svg?url';

export const CityOne = () => {
  return <Image image={{src: cityOneImage}} />;
};

export const CityTwo = () => {
  return <Image image={{src: cityTwoImage}} />;
};

export const CityThree = () => {
  return <Image image={{src: cityThreeImage}} />;
};

export const ErnieWithBinoculars = () => {
  return <Image image={{src: ernieWithBinocularsImage}} />;
};

export const ErnieFemaleWaving = () => {
  return <Image image={{src: ernieFemaleWavingImage}} />;
};

export const ErnieHighfiveAnother = () => {
  return <Image image={{src: ernieHighfiveAnotherImage}} />;
};

export const ErnieTangledUp = () => {
  return <Image image={{src: ernieTangledUpImage}} />;
};

export const AlignBackground = ({top, left, right, bottom, children}) => {
  return (
    <div style={{position: 'absolute', top, left, right, bottom}}>
      {children}
    </div>
  );
};
