/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useState } from 'react';

import BodyText from 'components/BodyText';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';
import PathLink from 'components/Link/PathLink';
import ProblemErnie from 'components/ProblemErnie';
import Icon, { IconTypes } from 'components/Icon';

import { MetadataTargetCompanyStatus } from 'config/constants/registration';

import ExpeditedRegistrationWorkflow from 'containers/Registration/ExpeditedRegistrationWorkflow';

import useIsAuthorizedToUseApp from 'hooks/identity/useIsAuthorizedToUseApp';
import {
  GetSecureTokenDataHooks,
  useRegistrationMetadata,
  useRegistrationResult
} from 'hooks/registration';

import BlueHeaderOnWhiteLayout from 'layouts/BlueHeaderOnWhite';
import useWindowWidth from 'hooks/browser/useWindowWidth';

import { AuthRedirect } from 'routes/utils';

import VccSelfEnrollment from 'views/Onboarding/VccSelfEnrollment';

import Messages from './index.messages';

const SelfEnrollment = () => {
  // Hooks
  const {
    data,
    isValid,
    state,
    token: secureToken
  } = GetSecureTokenDataHooks.useContext() ?? {};

  const windowWidth = useWindowWidth();

  const { targetCompanyInfo } = useRegistrationMetadata() ?? {};
  const { isRegistered } = useRegistrationResult() ?? {};

  const {
    status
  } = targetCompanyInfo ?? {};

  const isAuthorizedToUseApp = useIsAuthorizedToUseApp();
  const [ wasAuthorizedToUseApp ] = useState(isAuthorizedToUseApp);

  if (!isValid && state === 'Expired') {
    return (
      <BlueHeaderOnWhiteLayout
        contentWidth={650}
        hideLinks={true}
      >
        <div style={{ textAlign: 'center' }}>
          <Icon type={IconTypes.StopWatch} color="lightgray" size={50} />
          <br /><br />
          <div style={{ fontSize: '18px' }}>
            This link has expired.
            <br/><br/>
            Contact your trading partner to receive a new link.
          </div>
        </div>
      </BlueHeaderOnWhiteLayout>
    );
  }


  if (!isValid) {
    return (
      <BlueHeaderOnWhiteLayout
        contentWidth={windowWidth > 650 ? 650 : 'auto'}
        hideLinks={true}
      >
        <div style={{textAlign: 'center'}}>
          <Icon type={IconTypes.CircleExclamation} color="red" size={50} />
          <br/><br/>
          <div style={{fontSize: '18px'}}>
            Enrollment is not available at this time.
          </div>
        </div>
      </BlueHeaderOnWhiteLayout>
    );
  }

  const {
    canEnrollInElectronic,
    canEnrollInVcc,
    customerCompany
  } = data;

  if (canEnrollInVcc) {
    return (
      <BlueHeaderOnWhiteLayout
        contentWidth={windowWidth > 1000 ? 1000 : 'auto'}
        hideLinks={true}
      >
        <VccSelfEnrollment />
      </BlueHeaderOnWhiteLayout>
    );
  }

  if (canEnrollInElectronic) {
    if (isAuthorizedToUseApp) {
      if (!wasAuthorizedToUseApp) {
        return <AuthRedirect />;
      }

      return (
        <div className="text-center">
          <BodyText>You are already logged in.</BodyText>
          <div>
            <PathLink.Dashboard button={true}>
              Go to App
            </PathLink.Dashboard>
          </div>
        </div>
      );
    }

    const isPartiallyRegistered = status === MetadataTargetCompanyStatus.PartialRegistration
      || isRegistered;

    return (
      <BlueHeaderOnWhiteLayout
        contentWidth={650}
        hideLinks={true}
      >
        {isPartiallyRegistered ? (
          <>
            <HeaderText
              className="text-center"
              variant={HeaderTextVariants.Larger}
            >
              <Messages.RegisteredHeader.Message />
            </HeaderText>
            <BodyText className="text-center pad-20-y" style={{ width: '100%' }}>
              <Messages.RegisteredSubheader.Message />
            </BodyText>
          </>
        ) : (
          <>
            <HeaderText
              className="text-center"
              variant={HeaderTextVariants.Larger}
            >
              <Messages.Header.Message />
            </HeaderText>
            <BodyText className="text-center pad-20-y" style={{ width: '100%' }}>
              <Messages.Subheader.Message
                companyName={customerCompany}
              />
            </BodyText>
          </>
        )}
        <ExpeditedRegistrationWorkflow
          secureToken={secureToken}
        />
      </BlueHeaderOnWhiteLayout>
    );
  }

  return (
    <BlueHeaderOnWhiteLayout
      contentWidth={650}
      hideLinks={true}
    >
      <ProblemErnie
        centerInPage={true}
        errorReason="Unknown Flow"
      />
    </BlueHeaderOnWhiteLayout>
  );
};

export default SelfEnrollment;