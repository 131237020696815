/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import classNames from 'classnames';

import Button from 'components/Button';
import Border from 'components/Border';
import BodyText, { BodyTextVariants } from 'components/BodyText';
import CommonMessages from 'config/messages/common';
import PlaidBankSelectorWrapper from 'containers/PlaidBankSelectorWrapper';
import ImageTextLargeButton from 'components/ImageTextLargeButton';
import AddBankAccountManuallyStepForm
  from 'views/Settings/PaymentMethods/AddCheckingAccountManager/AddBankAccountManuallyStep/Form';
import AddSignatureAndTinForm
  from 'views/Settings/PaymentMethods/AddCheckingAccountManager/AddSignatureStep/Form';
import greenCheckmark from 'public/images/icons/green-checkmark.svg?url';
import selectAccountFromPortalIcon from 'public/images/icons/select-account-portal.svg?url';
import selectAccountManuallyIcon from 'public/images/icons/select-account-manually.svg?url';
import checkmarkCircleGreen from 'images/icons/check-green-2.svg?url';

import useFuncRef from 'hooks/react/useFuncRef';
import usePropRef from 'hooks/react/usePropRef';

import Messages from './index.messages';
import './index.scss';

const EnterBankOptionsGrid = ({
  hasPlaidLoadFailed,
  isPlaidLoading,
  onChange,
  onSelectPlaidBank: onSelectPlaidBankProp,
  canSkip,
  hasSkipped
}) => {
  // Hooks

  const onSelectPlaidBankRef = usePropRef(onSelectPlaidBankProp);

  const { current: onSelectPlaidBank } = useFuncRef(
    () => () => onSelectPlaidBankRef.current()
  );

  // Render
  const colClassName = canSkip ? 'col-4' : 'col-6';

  return (
    <>
      <ImageTextLargeButton
        className={classNames(
          colClassName,
          { 'plaid-disabled': isPlaidLoading || hasPlaidLoadFailed }
        )}
        disabled={isPlaidLoading || hasPlaidLoadFailed}
        style={{height: hasSkipped ? 35 : 100}}
        skinny={true}
        imgSrc={hasSkipped ? null : selectAccountFromPortalIcon}
        onClick={isPlaidLoading || hasPlaidLoadFailed ? null : onSelectPlaidBank}
      >
        <BodyText color={hasSkipped ? 'grey' : null}>
          <Messages.Plaid.Message />
        </BodyText>
      </ImageTextLargeButton>
      <ImageTextLargeButton
        className={colClassName}
        style={{height: hasSkipped ? 35 : 100}}
        skinny={true}
        imgSrc={hasSkipped ? null : selectAccountManuallyIcon}
        onClick={() => onChange({ mode: 'enterManually' })}
      >
        <BodyText color={hasSkipped ? 'grey' : null}>
          <Messages.Manually.Message />
        </BodyText>
      </ImageTextLargeButton>
      {canSkip ? (
        <ImageTextLargeButton
          className={colClassName}
          style={{height: hasSkipped ? 35 : 100}}
          skinny={true}
          imgSrc={hasSkipped ? null : selectAccountManuallyIcon}
          onClick={() => onChange({ mode: 'skipped' })}
        >
          <span>
            {hasSkipped ? (
              <img
                src={checkmarkCircleGreen}
                style={{ verticalAlign: 'middle', paddingRight: 8 }}
              />
            ) : null}
            <span style={{ verticalAlign: 'middle' }}>
              <Messages.Skip.Message />
            </span>
          </span>
        </ImageTextLargeButton>
      ) : null}
    </>
  );
};

const EnterBankInformation = ({
  canSkip,
  formRef,
  invitationId,
  needsSignature,
  onChange,
  signatureFormRef,
  value: originalValue
}) => {
  // Hooks
  const value = originalValue || {};

  const onChangeRef = usePropRef(onChange);
  const valueRef = usePropRef(value);

  const { current: onManualAccountChange } = useFuncRef(
    () => manualPaymentAccount => onChangeRef.current({
      mode: valueRef.current.mode,
      manualPaymentAccount,
      completePaymentAccount: valueRef.current.completePaymentAccount
    })
  );

  const { current: onManualAccountCompleteChange } = useFuncRef(
    () => completePaymentAccount => onChangeRef.current({
      mode: valueRef.current.mode,
      completePaymentAccount,
      manualPaymentAccount: valueRef.current.manualPaymentAccount
    })
  );

  const { current: onPlaidAccountSelected } = useFuncRef(
    () => (
      publicToken,
      metadata
    ) => onChangeRef.current({
      plaidPaymentAccount: {
        publicToken,
        accountId: metadata.account_id
      }
    })
  );

  const { current: onPlaidAccountCompleteChange } = useFuncRef(
    () => completePaymentAccount => onChangeRef.current({
      mode: valueRef.current.mode,
      completePaymentAccount,
      plaidPaymentAccount: valueRef.current.plaidPaymentAccount
    })
  );

  const { current: onReturn } = useFuncRef(
    () => () => onChangeRef.current({ mode: null })
  );

  const { current: returnLink } = useFuncRef(
    () => (
      <Button anchor={true} onClick={onReturn}>
        <CommonMessages.Cancel.Message />
      </Button>
    )
  );

  // Render
  if (value.mode === 'enterManually') {
    return (
      <>
        <AddBankAccountManuallyStepForm
          formRef={formRef}
          hideAccountName={true}
          formState={value.manualPaymentAccount || {}}
          onChange={onManualAccountChange}
        />
        {needsSignature ? (
          <AddSignatureAndTinForm
            formRef={signatureFormRef}
            invitationId={invitationId}
            onChange={onManualAccountCompleteChange}
            showTin={true}
            signatureId={invitationId}
            value={value.completePaymentAccount || {}}
          />
        ) : null}
        <BodyText variant={BodyTextVariants.Smaller}>
          <Messages.EnterManuallyReturn.Message returnLink={returnLink} />
        </BodyText>
      </>
    );
  }

  if (value.plaidPaymentAccount) {
    return (
      <>
        <Border style={{ padding: 16 }}>
          <img className="plaid-success-icon" src={greenCheckmark} />
          <Messages.EnterPlaidSuccess.Message />
        </Border>
        {needsSignature ? (
          <AddSignatureAndTinForm
            formRef={signatureFormRef}
            invitationId={invitationId}
            onChange={onPlaidAccountCompleteChange}
            showTin={true}
            signatureId={invitationId}
            value={value.completePaymentAccount || {}}
          />
        ) : null}
        <BodyText variant={BodyTextVariants.Smaller} style={{ paddingTop: 8 }}>
          <Messages.EnterPlaidReturn.Message returnLink={returnLink} />
        </BodyText>
      </>
    );
  }

  return (
    <PlaidBankSelectorWrapper
      ignoreLoading={true}
      handleAccountSelected={onPlaidAccountSelected}
    >
      <EnterBankOptionsGrid
        canSkip={canSkip}
        hasSkipped={value.mode === 'skipped'}
        onChange={onChange}
      />
    </PlaidBankSelectorWrapper>
  );
};

export default EnterBankInformation;
