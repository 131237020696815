/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Children, useContext } from 'react';
import AnalyticsContext from 'components/AnalyticsCategory/context';
import ProvideIsMobile from 'decorators/ProvideIsMobile';
import floatingCloseButtonIcon from 'public/images/icons/floating-close-icon.svg?url';
import classNames from 'classnames';
import './index.scss';

const FloatingCloseButton = ({
  onClose
}) => {
  const {
    analyticsCategory
  } = useContext(AnalyticsContext) || {};

  const getId = () => {
    if (analyticsCategory) {
      return `${analyticsCategory}.HeaderClose`;
    }

    return 'HeaderClose';
  };

  return (
    <div className="floating-close-button">
      <img id={getId()} onClick={onClose} src={floatingCloseButtonIcon} />
    </div>
  );
};

const CloseButton = ({isMobile, onClose}) => (
  !isMobile ? (
    <FloatingCloseButton onClose={onClose} />
  ) : (
    <div className="inline-close-button">
      <span onClick={onClose} className="vp-action delete"/>
    </div>
  )
);

const ModalHeader = ({children, isMobile, onClose}) => {
  if (!Children.count(children)) {
    if (!onClose) return <span/>;

    return (
      <div className= {classNames('basicModal', {'header': isMobile})}>
        <CloseButton isMobile={isMobile} onClose={onClose} />
      </div>
    );
  }
  return (
    <div className="basicModal header">
      {children}
      {onClose ? <CloseButton isMobile={isMobile} onClose={onClose} /> : null}
    </div>
  );
};

export default ProvideIsMobile(ModalHeader);
