/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

/* eslint react/no-multi-comp:0 */

import React, { Children, Component } from 'react';
import t from 'tcomb-validation';
import FormJSX, { FieldOption } from 'components/Form/FormJSX';
import Button from 'components/Button';
import PhoneNumber from 'components/PhoneNumber';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';
import BodyText from 'components/BodyText';
import PathLink from 'components/Link/PathLink';
import { Column, Row } from 'components/Viewstrap/Grid';
import PasswordStrengthField from 'components/Form/Inputs/PasswordStrengthTextBox';
import PasswordToggleTextBox from 'components/Form/Inputs/PasswordToggleTextBox';
import createBaseTwoStepSetupForm from 'containers/Settings/TwoStepVerification/Base';
import { createMatchingPassword, Password } from 'schemas/common/password';
import monitorIcon from 'public/images/icons/monitor.svg?url';
import Messages from './index.messages';
import connect from  './index.connect';

const PasswordForm = password => t.struct({
  password: Password,
  verifyPassword: createMatchingPassword(password)
});

const BaseProvideCredsAndSecureForm = ({
  passwordFormRef,
  passwordFormState,
  onPasswordFormChange,
  children,
  sendVerifyCode,
  isLoading,
  isSendCodeDisabled
}) => (
  <div style={{ paddingTop: 12 }}>
    <HeaderText variant={HeaderTextVariants.Small}>
      <Messages.CreatePassword.Message />
    </HeaderText>
    <FormJSX
      ref={passwordFormRef}
      value={passwordFormState}
      onChange={onPasswordFormChange}
      modelType={PasswordForm(passwordFormState.password)}
    >
      <FieldOption name="password"
        label={Messages.Password}
        inputComponent={PasswordStrengthField}
        cols={6}
        inputProps={{isToggleEnabled: true}}
      />
      <FieldOption name="verifyPassword"
        label={Messages.VerifyPassword}
        cols={6}
        inputComponent={PasswordToggleTextBox}
        inputProps={{isToggleEnabled: true}}
      />
    </FormJSX>
    <HeaderText variant={HeaderTextVariants.Small} style={{ paddingTop: 8 }}>
      <Messages.SecureYourAccount.Message />
    </HeaderText>
    {Children.only(children)}
    <Button
      onClick={sendVerifyCode}
      expand={true}
      isDisabled={isSendCodeDisabled || isLoading}
      isProcessing={isLoading}
    >
      <Messages.SubmitButton.Message />
    </Button>
  </div>
);

const FlatSecureAccount = ({
  isSendingPhoneNumber,
  isVerificationPending,
  phoneFormRef,
  onPhoneFormChange,
  phoneFormModelType,
  verifyFormRef,
  onVerifyFormChange,
  verifyFormModelType,
  state,
  sendPhoneNumber,
  onResendCode,
  onResetVerification
}) => {
  let codeError = null;
  if (state.codeError) {
    switch (state.codeError) {
      case 'IncorrectCode':
        codeError = Messages.IncorrectCode.defaultMessage;
        break;
      case 'PhoneLocked':
        codeError = Messages.PhoneNumberLocked.defaultMessage;
        break;
      default:
    }
  }

  return (
    <Row addColumnGap={true}>
      <Column size={6}>
        {isVerificationPending ? (
          <Row className="pad-y-half">
            <div className="vp-label vp-label-indent">
              <Messages.PhoneNumberLabel.Message />
            </div>
            <div>
              <div style={{paddingBottom: '4px 0'}}>
                <Messages.VerifyCodeInstructions.Message
                  phoneNumber={<PhoneNumber value={state.twoStepForm.phoneNumber} />}
                />
              </div>
              <div>
                <Button anchor={true} onClick={onResendCode}>
                  <Messages.ResendCode.Message />
                </Button>
                &nbsp;|&nbsp;
                <Button anchor={true} onClick={onResetVerification}>
                  <Messages.ChangeNumber.Message />
                </Button>
              </div>
            </div>
          </Row>
        ) : (
          <Row className="pad-y-half">
            <div className="vp-label vp-label-indent">
              <Messages.PhoneNumberLabel.Message />
            </div>
            <FormJSX ref={phoneFormRef}
              onChange={onPhoneFormChange}
              modelType={phoneFormModelType}
              value={state.twoStepForm}
              hasSubmitButton={true}
              customConfig={{ addPadding: 'none' }}
            >
              <FieldOption
                name="phoneNumber"
                willHideLabel={true}
                cols={9}
                doNotValidateOnBlur={true}
                placeholder={Messages.PhoneNumberPlaceholder}
              />
              <Button
                cols={3}
                onClick={sendPhoneNumber}
                className="skinny-button"
                type="submit"
                isDisabled={isSendingPhoneNumber}
                isProcessing={isSendingPhoneNumber}
              >
                <Messages.SendButton.Message />
              </Button>
            </FormJSX>
          </Row>
        )}
      </Column>
      <Column size={6}>
        <FormJSX
          ref={verifyFormRef}
          onChange={onVerifyFormChange}
          modelType={verifyFormModelType}
          value={state.codeForm}
          disabled={!isVerificationPending}
        >
          <FieldOption
            name="authenticationCode"
            cols={12}
            label={Messages.VerifyCodeLabel}
            error={codeError}
            doNotValidateOnBlur={true}
            placeholder={Messages.VerifyCodePlaceholder}
          />
        </FormJSX>
      </Column>
    </Row>
  );
};

const ProvideCredsAndSecureForm = createBaseTwoStepSetupForm(
  BaseProvideCredsAndSecureForm,
  FlatSecureAccount
);

const Wrapper = ({ children, hideHeader, metadata, isPendingPaymentAcount }) => {
  if (hideHeader) {
    return (
      <div>
        {Children.only(children)}
      </div>
    );
  }

  return (
    <div>
      <div style={{textAlign: 'center'}}>
        <img src={monitorIcon} />
      </div>
      <HeaderText style={{textAlign: 'center', padding: '8px 0'}}>
        {isPendingPaymentAcount ? (
          <Messages.LandingHeaderPendingAccount.Message />
        ) : (
          <Messages.LandingHeader.Message />
        )}
      </HeaderText>
      <BodyText style={{textAlign: 'center', paddingBottom: 12}}>
        {isPendingPaymentAcount ? (
          <Messages.LandingInstructionsPendingAccount.Message companyName={metadata.from.companyName} />
        ) : (
          <Messages.LandingInstructions.Message companyName={metadata.from.companyName} />
        )}
      </BodyText>
      {Children.only(children)}
    </div>
  );
};

class ProvideCredentialsForm extends Component {
  constructor(props) {
    super(props);
    this.state = { formValue: {} };
  }

  onVerifyCode(twoFactorAuthenticationCode) {
    const value = this.formRef.getValue();

    if (value) {
      this.props.onSubmit({
        password: value.password,
        twoFactorAuthenticationCode
      });
    }
  }

  render () {
    const {
      forceCreateAccount,
      hideHeader,
      isPendingPaymentAcount,
      metadata,
      provideResponse: originalProvideResponse
    } = this.props;
    const provideResponse = originalProvideResponse || {};

    if (!this.state.isCreatingAccount && !forceCreateAccount) {
      return (
        <Wrapper metadata={metadata} isPendingPaymentAcount={isPendingPaymentAcount} hideHeader={hideHeader}>
          <div style={{textAlign: 'center', paddingTop: 12}}>
            <PathLink.MarketingSite
              target="_self"
              button={{variant: 'secondary'}}
              style={{ minWidth: 150 }}
            >
              <Messages.LandingCancelButton.Message />
            </PathLink.MarketingSite>
            <Button
              onClick={() => this.setState({ isCreatingAccount: true })}
              style={{ minWidth: 150 }}
            >
              <Messages.LandingContinueButton.Message />
            </Button>
          </div>
        </Wrapper>
      );
    }

    return (
      <Wrapper metadata={metadata} isPendingPaymentAcount={isPendingPaymentAcount} hideHeader={hideHeader}>
        <ProvideCredsAndSecureForm
          passwordFormRef={r => this.formRef = r}
          passwordFormState={this.state.formValue}
          onPasswordFormChange={formValue => this.setState({ formValue })}
          isLoading={this.props.isLoading}
          isPhoneNumberLocked={provideResponse.twoFactorStatus === 'LockedNumber'}
          isVerificationSuccessful={provideResponse.twoFactorStatus === 'Success'}
          isVerificationPending={this.props.isVerificationPending}
          isVerifying={this.props.isLoading}
          onEnable={this.props.onCreateTwoFactor}
          onResendCode={() => this.props.onResendCode()}
          onResetVerification={() => this.props.onResetPhoneNumber()}
          onVerifyCode={value => this.onVerifyCode(value)}
        />
      </Wrapper>
    );
  }
}

export default connect(ProvideCredentialsForm);
