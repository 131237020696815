/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import canUseDOM from 'utils/browser/canUseDOM';
import { MOBILE_BREAK } from 'config/constants';
import StandardMenuRenderer from './StandardMenuRenderer';
import MobileMenuRenderer from './MobileMenuRenderer';

export function detectDevice() {
  // TODO: server side strategy - agent string?

  if (!canUseDOM) {
    throw new Error('detectDevice currently only supports running inside browser.');
  }

  return { isMobile: window.innerWidth < MOBILE_BREAK };
}

export default function resolveMenuRenderer() {
  let result = detectDevice();

  if (result.isMobile) {
    return new MobileMenuRenderer();
  }

  return new StandardMenuRenderer();
}

export class MenuRenderer {
  constructor(renderer) {
    this.renderer = renderer;
  }

  renderItems(items) {
    return items.map((menuItem, index) => {
      this.renderMenuItem(menuItem, index);
    });
  }

  renderMenuItem(menuItem, index) {
    return this.renderer.renderMenuItem(menuItem, index);
  }
}
