/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';

import BodyText from 'components/BodyText';
import Border from 'components/Border';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';
import PathLink from 'components/Link/PathLink';
import { FlexColumn, FlexGrid } from 'components/Viewstrap/Grid';

import CommonMessages from 'config/messages/common';

import corporatePaymentsIcon from 'public/images/registration/corporatePaymentsIcon.svg?url';
import smallBusinessIcon from 'public/images/registration/smallBusinessIcon.svg?url';

import Messages from './index.messages';

const RegistrationOpenLanding = () => {
  return (
    <>
      <div className="text-center pad-40-bottom">
        <HeaderText className="pad-16-bottom" variant={HeaderTextVariants.PageHeader}>
          <Messages.Header.Message />
        </HeaderText>
        <BodyText color="darkGrey">
          <Messages.Subheader.VpMessage />
        </BodyText>
      </div>
      <FlexGrid gutter={true}>
        <FlexColumn size={6}>
          <Border
            style={{ borderRadius: 10, padding: '0px 28px' }}
          >
            <div className="text-center pad-40-y">
              <HeaderText cvariant={HeaderTextVariants.Larger}>
                <Messages.CorporateHeader.Message />
              </HeaderText>
              <Messages.CorporateSubheader.Message />
              <div>
                <img src={corporatePaymentsIcon} />
              </div>
              <BodyText className="pad-12-top pad-16-bottom">
                <Messages.CorporateDescription.Message/>
              </BodyText>
              <PathLink.Registration.CorporatePaymentsContact button={true}>
                <Messages.CorporateContactUs.Message />
              </PathLink.Registration.CorporatePaymentsContact>
            </div>
          </Border>
        </FlexColumn>
        <FlexColumn size={6}>
          <Border
            className="text-center"
            style={{ borderRadius: 10, padding: '0px 28px' }}
          >
            <div className="text-center pad-40-y">
              <HeaderText variant={HeaderTextVariants.Larger}>
                <Messages.SmallBusinessHeader.Message />
              </HeaderText>
              <Messages.SmallBusinessSubheader.Message />
              <div>
                <img src={smallBusinessIcon} />
              </div>
              <BodyText className="pad-12-top pad-16-bottom">
                <Messages.SmallBusinessDescription.Message/>
              </BodyText>
              <PathLink.Registration.SmallBusinessRegistration.Features button={true}>
                <CommonMessages.GetStarted.Message />
              </PathLink.Registration.SmallBusinessRegistration.Features>
            </div>
          </Border>
        </FlexColumn>
      </FlexGrid>
    </>
  );
};

export default RegistrationOpenLanding;