/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { ViewpostCustomerSupport } from 'config/messageLinks';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';
import Border from 'components/Border';
import greyCheckmark from 'public/images/icons/grey-checkmark.svg?url';
import { SmallBannerNotification, NotificationStyleTypes } from 'components/Notification';
import {
  MetadataTargetCompanyStatus,
  MetadataTargetCompanyPaymentStatus,
  ShowAddPaymentAccountStatus
} from 'config/constants/registration';
import { CustomerSupport } from 'config/messageLinks';
import ProvideCredentialsForm from './Form';
import connect from './index.connect';
import Messages from './index.messages';
import ParentMessages from '../index.messages';

const RegistrationWithPlaidSuccess = ({ companyName }) => (
  <SmallBannerNotification type={NotificationStyleTypes.Success}>
    <Messages.PlaidSubheader.VpMessage companyName={companyName} />
  </SmallBannerNotification>
);

const RegistrationWithMdsSuccess = ({ addBorder }) => {

  const content = (
    <div className="registration-success">
      <img src={greyCheckmark} />
      <HeaderText className="header" variant={HeaderTextVariants.Medium}>
        <Messages.ManualHeader.Message />
      </HeaderText>
      <Messages.ManualSubheader.Message customerSupportLink={ViewpostCustomerSupport} />
    </div>
  );

  return addBorder ? (
    <Border>
      {content}
    </Border>
  ) : content;
};

const ProvideCredentials = ({
  registrationResult,
  isLoading,
  isAuthorizedToUseApp,
  metadata,
  onProvideCredentials,
  provideResponse,
  wasManualFlow
}) => {
  let {
    isRegistered,
    isPaymentAccountRegistered
  } = registrationResult || {};

  const {
    status: targetCompanyStatus,
    paymentAccountStatus,
    connectionType
  } = metadata.targetCompanyInfo || {};

  const { displayAddPaymentAccount } = metadata || {};

  const needsToVerifyMds = wasManualFlow || paymentAccountStatus === MetadataTargetCompanyPaymentStatus.Pending;

  if (targetCompanyStatus === MetadataTargetCompanyStatus.PartialRegistration) {
    isRegistered = true;
  }

  if (paymentAccountStatus === MetadataTargetCompanyPaymentStatus.Pending
    || paymentAccountStatus === MetadataTargetCompanyPaymentStatus.Registered) {
    isPaymentAccountRegistered = true;
  }

  let paymentAccountResult = null;
  let action = null;

  if (isRegistered && isPaymentAccountRegistered) {
    if (needsToVerifyMds) {
      paymentAccountResult = (
        <RegistrationWithMdsSuccess
          addBorder={displayAddPaymentAccount !== ShowAddPaymentAccountStatus.Required}
        />
      );
    } else if (connectionType !== 'Customer') {
      paymentAccountResult = <RegistrationWithPlaidSuccess companyName={metadata.from.companyName} />;
    }
  }

  if (isRegistered) {
    // If you have a temporary session or came straight from the registration form
    if (isAuthorizedToUseApp || !!registrationResult) {
      action = (
        <ProvideCredentialsForm
          forceCreateAccount={connectionType === 'Customer'
            || displayAddPaymentAccount !== ShowAddPaymentAccountStatus.Required}
          hideHeader={connectionType === 'Customer'
            || displayAddPaymentAccount !== ShowAddPaymentAccountStatus.Required}
          isLoading={isLoading}
          isPendingPaymentAcount={paymentAccountStatus === MetadataTargetCompanyPaymentStatus.Pending}
          onSubmit={onProvideCredentials}
          provideResponse={provideResponse}
          metadata={metadata}
        />
      );
    } else {
      action = <Messages.UnauthPartialRegistration.Message customerSupportLink={ViewpostCustomerSupport} />;
    }
  }

  if (action && paymentAccountResult) {
    action = (
      <div style={{paddingTop: 28}}>
        {action}
      </div>
    );
  }

  if (paymentAccountResult || action) {
    return (
      <div className="expedited-registration">
        {paymentAccountResult}
        {action}
      </div>
    );
  }

  return (
    <SmallBannerNotification>
      <ParentMessages.Failure.Message customerSupport={CustomerSupport} />
    </SmallBannerNotification>
  );
};

export default connect(ProvideCredentials);
