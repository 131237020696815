/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('TokenLanding.VccSelfEnrollment', {
  SelectFlowHeader: 'Can you receive credit card payment instructions via email?',
  SelectFlowSubheader: 'By default, Virtual Card payment instructions and remittance are sent via email and can be processed as a normal credit card transaction. If you can’t process payments via email, select “NO” and our payment specialists will provide assistance.',
  SelectFlowFooter: 'Assistance with Virtual Card transactions may include processing payments by phone, using a payment portal, or through a card merchant’s website. Payment limits and transaction fees can also be accommodated. You will be able to provide additional instructions on the next screen.',
  Header: 'Virtual Credit Card Enrollment',
  Subheader: 'Please complete this form to receive Virtual Card payments from {companyName}.'
});

export const FormMessages = toMessages('TokenLanding.VccSelfEnrollment.Form', {
  CompanyName: 'Company Name',
  FirstName: 'First Name',
  LastName: 'Last Name',
  Title: 'Title',
  Phone: 'Work Phone',
  Email: 'Email Address (to contact with questions)',
  PaymentInstructionsEmail: 'Email Address (to receive payment instructions)',
  VerifyEmail: 'Verify Email Address',
  PaymentInstructions: 'Payment Instructions or Limitations for Assisted Pay',
  PaymentInstructionsPlaceHolder: 'Please provide us with any information our team will need to pay you via credit card.',
  TermsOfService: 'I have reviewed and accept the Payment Terms.',
  Submit: 'Submit to Enroll'
});

export const AboutMessages = toMessages('TokenLanding.VccSelfEnrollment.Amount', {
  Header: 'About Virtual Card Payments',
  Subheader: '{companyName} offers Virtual Credit Card payments as way to increase the speed and security of your payments. Virtual Card payments are processed just like a normal credit card transaction, and they offer many benefits for your business. If you are able to accept card payments, simply submit the enrollment form to get started.',
  Bullet1: 'Enrollment only takes 1-2 minutes',
  Bullet2: 'Virtual Card payments are processed just like normal credit card transactions',
  Bullet3: 'Remittance information is provided with each payment',
  Bullet4: 'Eliminates paper check handling and enables payments to be received faster',
  Bullet5: 'Removes risk of check fraud',
  Bullet6: 'No fees are charged by {companyName} or Viewpost (payment provider)'
});

export const FaqMessages = toMessages('TokenLanding.VccSelfEnrollment.Faq', {
  Header: 'Frequently Asked Questions',
  Question1: 'What are Virtual Credit Card payments?',
  Question1Answer: 'Virtual Credit Card, or Virtual Card, payments are credit card transactions that are processed for a specific payment amount using a unique 16-digit number for each transaction. This makes Virtual Card transactions highly secure.',
  Question2: 'Who is Viewpost?',
  Question2Answer: 'Viewpost is a payment services provider that works with {companyName} to increase the speed, efficiency, and security of payment to its suppliers.',
  Question3: 'How are Virtual Card payments processed?',
  Question3Answer: 'Virtual Card payments are processed like normal card transactions using your merchant account services. For assistance or special requirements, see below.',
  Question4: 'How am I notified of payments?',
  Question4Answer: 'You will receive an email that contains the payment amount, unique card number and remittance information for you to process the payment. You simply process each payment as a normal card transaction. For assistance or special requirements, see below.',
  Question5: 'Are there additional fees for Virtual Card payments?',
  Question5Answer: 'No. Neither {companyName} or Viewpost charge fees for Virtual Card payments. Your normal merchant service fees will apply.',
  Question6: 'What if my company requires fees, has transaction limits, or needs assistance processing card transactions?',
  Question6Answer: 'Viewpost provides assistance to companies with unique payment needs. If any of the items below are applicable, please {switchToAssisted} — include specific instructions or requirements for processing card transactions. If needed, a representative will contact you.',
  SwitchLink: 'use this form to enroll for Assisted Pay',
  Question6Bullet1: 'Your company uses a vendor or payment portal',
  Question6Bullet2: 'You need to process card transactions by phone',
  Question6Bullet3: 'You need to charge a fee on card transactions',
  Question6Bullet4: 'You have a limit on the amount that can be paid by card',
  Question6Bullet5: 'You have other requirements, limitations or special needs'
});

export const AssistedFaqMessages = toMessages('TokenLanding.VccSelfEnrollment.AssistedFaq', {
  Header: 'What is Assisted Pay?',
  Instructions1: 'Assisted Pay refers to Virtual Card payments that require additional involvement from Viewpost or the card-issuing bank to complete the transactions. Assisted Pay may include processing payments by phone, using a payment portal, or through a card merchant’s website. In addition, Assisted Pay can accommodate special payment thresholds or transaction fees that your company may require. There are no fees for Assisted Pay—simply include specific payment instructions in the form above and a specialist will automatically assist in processing card payments from {companyName}.',
  Instructions2: 'If you don’t need Assisted Pay and can receive payment instructions via email, please {switchToDirect}. Payments will be sent by email for you to process as normal credit card transactions.',
  SwitchLink: 'enroll using this form'
});

export const ConfirmationMessages = toMessages('TokenLanding.VccSelfEnrollment.Confirmation', {
  Header: 'Virtual Credit Card enrollment is complete.',
  InstructionsBeingSent: '<b>A confirmation email is being sent to {email}.</b> {followUp}',
  InstructionsSent: '<b>A confirmation email has been sent to the email on file.</b> {followUp}',
  InstructionsFollowUp: 'If you have questions about your enrollment or receiving card payments, please contact {supportLink}.'
});