/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useRef, useState } from 'react';

import Icon, { IconTypes } from 'components/Icon';
import TermsContent from 'views/Terms/TermsContent';

import './index.scss';

const ScrollWrapContainer = ({
  children,
  onScrollToEnd,
  onScrollChange,
  height = '400px'
}) => {
  const ref = useRef(null);

  const onScroll = () => {
    const container = ref.current;
    if (container) {
      const { scrollTop, scrollHeight, offsetHeight } = container;
      let isScrolledToBottom = scrollHeight - scrollTop <= offsetHeight;

      if (isScrolledToBottom) {
        onScrollToEnd?.();
      }

      onScrollChange?.({
        isAtBottom: isScrolledToBottom
      });
    }
  };

  useEffect(() => {
    const container = ref.current;
    if (container) {
      container.addEventListener('scroll', onScroll);
    }

    // Cleanup on unmount
    return () => {
      if (container) {
        container.removeEventListener('scroll', onScroll);
      }
    };
  }, []);

  return (
    <div ref={ref}
      style={{
        height: height,
        overflowY: 'scroll',
        border: '1px solid #ddd',
        backgroundColor: '#f7f7f7',
        padding: '8px'
      }}>
      {children}
    </div>
  );
};

function TermsScrollWrap({
  onScrollToEnd,
  onScrollChange,
  height = '400px'
}) {
  const [isScrolled, setScrolled] = useState(false);

  return (
    <div className="component-terms-scrollwrap">
      <ScrollWrapContainer
        onScrollChange={onScrollChange}
        onScrollToEnd={() => {
          setScrolled(true);
          onScrollToEnd?.();
        }}
        height={height}>
        <TermsContent type="vccTerms" showHeading={false} showLastUpdated={false} />
      </ScrollWrapContainer>
      <div style={{
        border: '1px solid #ddd',
        borderTop: '0 none',
        padding: '8px',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        textAlign: 'center',
        color: '#999'
      }}>
        {isScrolled
          ? (
            <div>
              <Icon type={IconTypes.CircleCheck} color="green" />
              Terms Viewed
            </div>
          )
          : (
            <div>
              <div className="bounce" style={{ display: 'inline-block' }}>
                <Icon type={IconTypes.DownArrow2} color="gray" />
              </div>
              Please read through terms before continuing...
              <div className="bounce" style={{ display: 'inline-block' }}>
                <Icon type={IconTypes.DownArrow2} color="gray" />
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default TermsScrollWrap;